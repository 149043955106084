 import img1 from "../asset/images/home/whykluesicon/Expertise.png"
 import img2 from "../asset/images/home/whykluesicon/TailoredSol.png"
 import img3 from "../asset/images/home/whykluesicon/ExtensiveNet.png"
 import img4 from "../asset/images/home/whykluesicon/Innovation.png"
 import img5 from "../asset/images/home/whykluesicon/Commitment.png"
 import img6 from "../asset/images/home/whykluesicon/Enduring.png"
 import img7 from "../asset/images/home/whykluesicon/Transperent.png"
 import img8 from "../asset/images/home/whykluesicon/Betterout.png"
 
 
 export const whykluedata = [
    {
      id: "01",
      image: img1,
      text: "Personalized Service",
      content:"Tailored solutions and expertise crafted to meet unique needs and to drive your business forward.​",
    },
    {
      id: "02",
      image: img2,
      text: "Analysis",
      content: "Conduct comprehensive analysis of organizational goals, identifying skills gaps and challenges to enhance workforce capabilities.​",
    },
    {
      id: "03",
      image: img3,
      text: "Innovation",
      content: "We consistently stay ahead by providing inventive ideas and solutions for achieving success in a rapidly evolving market.",
    },
    {
      id: "04",
      image: img4,
      text: "Commitment to Quality",
      content: "Quality is our priority at every step, from exceptional deliverables to unparalleled client support.​",
    },
    {
      id: "05",
      image: img5,
      text: "Enduring Partnerships",
      content: "We transcend mere service provider; rather, we function as your strategic ally, fully committed to ensure enduring prosperity.",
    },
    {
      id: "06",
      image: img6,
      text: "Transparent Communication",
      content: "Anticipate transparent and open communication at every stage, fostering trustworthiness and accountability.",
    },
    {
      id: "07",
      image: img7,
      text: "Extensive Network",
      content: "Our widespread grid connects you with top-notch solutions and promising opportunities to create mutual partnerships.",
    },
    {
      id: "08",
      image: img8,
      text: "Better Outcomes",
      content: " Delivering superior results through innovative solutions and dedicated support for your success.​",
    }


]

export const aboutusValue = [
  {
    id: "01",
    image: img1,
    text: "Passion",
    content:"We are passionate about what we do, and our enthusiasm drives us to achieve excellence.​",
  },
  {
    id: "02",
    image: img2,
    text: "Integrity",
    content: "We uphold the highest standards of integrity in all our actions.​",
  },
  {
    id: "03",
    image: img3,
    text: "Respect",
    content: "We respect our clients, employees, and partners, fostering a culture of mutual trust and respect.",
  },
  {
    id: "04",
    image: img4,
    text: "Customer Focus",
    content: "Our clients are at the heart of everything we do, and we strive to exceed their expectations.​",
  },
  {
    id: "05",
    image: img5,
    text: "People Oriented",
    content: "We value our people and invest in their growth and development.",
  },
  {
    id: "06",
    image: img6,
    text: "Empowerment",
    content: "We empower our employees to take initiative and make decisions.",
  },
  {
    id: "07",
    image: img7,
    text: "Agility",
    content: "We are agile and adaptable, responding swiftly to changing market needs.",
  },
  {
    id: "08",
    image: img8,
    text: "Innovation",
    content: "We foster a culture of innovation to stay ahead in the market.",
  }
]
import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box } from "@mui/material";

const MarchImageSlider = ({ data, selectedImage, handleImageClick }) => {
  const sliderRef = useRef(null);

  const filteredData = data.filter(step => step.image !== selectedImage);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        settings: {
          vertical: false,
          verticalSwiping: true,
        },
      }
    ],
  };

  const handleClick = (image) => {
    if (handleImageClick) {
      handleImageClick(image);
    }
  };

  return (
    <Box sx={{ width: 300 }}>
      <Slider {...settings} ref={sliderRef}>
        {filteredData.map((step, index) => (
          <Box key={index} onClick={() => handleClick(step.image)}>
            <Box
              borderRadius={8}
              component="img"
              src={step.image}
              style={{
                objectFit: "contain",
                height: 100,
                width: 150,
              }}
            />
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default MarchImageSlider;

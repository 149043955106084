import img1 from "../asset/images/about/values/1.png";
import img2 from "../asset/images/about/values/2.png";
import img3 from "../asset/images/about/values/3.png";
import img4 from "../asset/images/about/values/4.png";
import img5 from "../asset/images/about/values/5.png";
import img6 from "../asset/images/about/values/6.png";


export const cardData = [
    {
      id: 1,
      text: "Integrity",
      Number: "01",
      content1: [
        "Integrity lies at the heart of our organization. ",
        "We uphold the highest ethical standards in all our interactions, prioritizing honesty, transparency, and safeguard data.",
      ],
    },
    {
      id: 2,
      text: "Empowerment",
      Number: "02",
      content1: [
        "Our commitment to empowerment fosters a culture of growth & resilience.",
        "We believe in empowering individuals to reach their full potential by providing them with the resources, support, and opportunities.",
      ],
    },
    {
      id: 3,
      text: "Customer-Centric",
      Number: "03",
      content1: [
        "Being customer-centric is a core value that guides everything we do.",
        "We prioritize understanding and meeting the unique needs and objectives of our clients, ensuring their success and satisfaction.",
      ],
    },
    {
      id: 4,
      text: "Resilience",
      Number: "04",
      content1: ["Resilience empowers us to navigate uncertainties and embrace changes.",
       "With resilience as our foundation, we continuously evolve, learn, and grow, ensuring sustained success for our clients."
      ],
    },
    {
      id: 5,
      text: "Innovation",
      Number: "05",
      content1: [
        "Innovation is the cornerstone of our organization to stay ahead of the curve.",
        "We constantly strive to push boundaries, challenge the status quo, and explore new ideas to enhance our services.",
      ],
    },
    {
      id: 6,
      text: "Array",
      Number: "06",
      content1: [
        "Adapting swiftly and rejuvenating oneself in response to rapid changes is a key skill for navigating today's dynamic world.​",
        // "Our commitment to assortment ensures that we attract top talent, cultivate a culture of inclusivity and diversity.",
      ],
    },
  ];

  export const valueData = [
    {
      id: "1",
      img: img1,
      title: "Expertise",
      des: "A team of experienced professionals with deep industry knowledge.​",
    },
    {
      id: "2",
      img: img5,
      title: "Innovation",
      des: "Cutting-edge solutions that drive business transformation.",
    },
    {
      id: "3",
      img: img3,
      title: "Client-Centric",
      des: "Customized solutions designed to meet the unique needs of each client.​",
    },
    {
      id: "4",
      img: img4,
      title: "Reliability",
      des: "Consistent delivery of high-quality services.​",
    },
    {
      id: "5",
      img: img2,
      title: "Comprehensive",
      des: "From consultation to implementation, we provide end-to-end support.​",
    },
  
  ];
import React from 'react'
import SubHeader from '../component/SubHeader'
import { Box } from '@mui/material'
import Layout from '../component/Layout'
import { Container } from "@mui/material";
import { Double } from '../data/SubHeaderData';
import ReferForm from '../component/ReferForm';

const ReferyourFriend = () => {
  return (
    <Layout>
      <Container sx={{maxWidth:{lg:"lg", xl:"xl",md:"md",sm:"sm",xs:"xs"}}}>
      <Box sx={{ mt:15}}>
        <SubHeader data={Double}/>
      </Box>

      <Box py={8}>
        <ReferForm/>
      </Box>
     </Container>
    </Layout>
  )
}

export default ReferyourFriend
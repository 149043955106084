import sideImg from "../asset/images/home/tabImg.png";
import star from "../asset/images/home/star.png";

export const KluesBringData = [
  {
    img: sideImg,
    StarImg: star,
    tabs: ["A", "B", "C", "D"],

    tabContent: [
      {
        title: "Approach",
        contents: [
          {
            Text1:"Tailored IT solutions and recruitment strategies to align with your unique business needs and objectives, ensuring a perfect fit for your organization.",
          },
          {
            Text1:"In-depth assessments to understand your specific requirements and challenges, allowing us to design training programs that address your unique needs.​",
          },
          {
            Text1:"Valuable insights and expert guidance, empowering you to make informed decisions about IT investments, talent acquisition, and skill development.​",
          },
          {
            Text1:"Ongoing evaluation and continuos improvement of our IT solutions, recruitment processes, and training programs to ensure they remain relevant and effective.​",
          },
        ],
      },

      {
        title: "Benchmark",
        contents: [
          {
            Text1:"Our services set the benchmark for innovation and excellence, leveraging the latest technologies to propel your business forward.",
          },
          {
            Text1:"Comprehensive resource hub to empower clients in their IT endeavors, recruitment processes, and training initiatives.​",
          },
          {
            Text1:"Foster sustainable growth, recognition, and advancement.",
          },
          {
            Text1:"Establish rigorous quality assurance measures and performance metrics, ensuring consistent delivery of exceptional results.",
          },
        ],
      },
      {
        title: "Customer Centric",
        contents: [
          {
            Text1:"Committed to providing proactive and responsive support, maintaining open lines of communication to address any concerns, and ensuring a seamless experience .",
          },
          {
            Text1:"Value your input and actively seek your feedback to refine our IT services and recruitment strategies continually.​",
          },
          {
            Text1:"Fostering a culture of collaboration, we ensure that our solutions and services evolve in tandem with your changing needs.",
          },
          {
            Text1:"Prioritize understanding your business goals and challenges to deliver customized solutions.​",
          },
        ],
      },
      {
        title: "Detailed",
        contents: [
          {
            Text1:"Thorough assessments of your existing IT infrastructure, workflows, and business objectives to optimize efficiency.​",
          },
          {
            Text1:"Transparent communication and detailed project planning, providing you with comprehensive timelines, milestones, and deliverables.",
          },
          {
            Text1:"Ensure clarity and alignment in every step of the workflow.",
          },
          {
            Text1:"Provide detailed insights and recommendations.",
          },
        ],
      },
    ],
  },
];

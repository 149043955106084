import React, { useState, useEffect, useRef } from "react";
import Layout from "../component/Layout";
import { Box, Card, Container, Grid, Stack, Typography } from "@mui/material";
import SubHeader from "../component/SubHeader";
import { merchandise } from "../data/SubHeaderData";
import SliderMarch from "../component/SliderMarch";
import { OfficeEssentialsData, ProductHigh } from "../data/MerchOfficeEssentialsData";
import { EGadgets } from "../data/MerchEGadgetsData";
import { ApparelsData } from "../data/MerchApparelsData";
import QualityAssurance from "./QualityAssurance";
import { HiArrowLongRight } from "react-icons/hi2";
import { Margin } from "@mui/icons-material";

const Merchandise = () => {
  const [disableScroll, setDisableScroll] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const cardRef = useRef(null);

  const handleScroll = (currentSlide, totalSlides) => {
    if (currentSlide !== 0 && currentSlide !== totalSlides - 1) {
      setDisableScroll(true);
    } else {
      setDisableScroll(false);
    }
  };

  useEffect(() => {
    const preventDefault = (e) => e.preventDefault();

    const cardElement = cardRef.current;

    if (disableScroll && cardElement) {
      cardElement.addEventListener('wheel', preventDefault, { passive: false });
      cardElement.addEventListener('touchmove', preventDefault, { passive: false });
    } else if (cardElement) {
      cardElement.removeEventListener('wheel', preventDefault);
      cardElement.removeEventListener('touchmove', preventDefault);
    }

    return () => {
      if (cardElement) {
        cardElement.removeEventListener('wheel', preventDefault);
        cardElement.removeEventListener('touchmove', preventDefault);
      }
    };
  }, [disableScroll]);

  return (
    <div>
      <Layout>
        <Container
          sx={{
            maxWidth: { lg: "lg", xl: "xl", md: "md", sm: "sm", xs: "xs" },
          }}
        >
          <Box sx={{ mt: 15 }}>
            <SubHeader data={merchandise} />
          </Box>
          <Grid py={5} my={8}
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{
              borderRadius: 5, width: '100%', ml: 0.2,
              '&:hover': {
                transition: 'all 0.5s',
                backgroundColor: '#DD952B',
                '& *': {
                  color: '#fff',
                  transition: 'all 0.5s'
                },
              },
            }}
          >
            <Grid item lg={3.5} md={3.5}>
              <Typography fontSize={40} fontWeight={600}>
                Product Highlights
              </Typography>
            </Grid>
            <Grid item lg={8} md={8}>
              <Typography fontSize={18} fontWeight={400}>
                Customized welcoming Kits: Make a lasting impression on your new hires with our comprehensive employee onboarding kits. Designed to streamline the integration process and foster a sense of belonging, our kits encompass three main categories.
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ my: 5, py: 5, px: 4, borderRadius: 5, }}>
            <Box pb={10}>
              <Typography color='#DD952B' fontWeight={600} fontSize={26} textAlign='right' pb={2}>
                Product Highlights
              </Typography >
            </Box>
            <Box position="relative" height='75vh' display='flex' justifyContent="center" alignItems='center'>
              <Box sx={{ position: "absolute", width: 200, left: 0, height: '100%', borderStartEndRadius: 30, borderEndEndRadius: 30, backgroundColor: 'rgb(from #F8A721 r g b / 30%)' }} />
              <SliderMarch slideData={ProductHigh} sx={{ flexDirection: 'row-reverse', mx: 5 }} />
            </Box>
          </Box>
          <Box sx={{ my: 5, py: 10, px: 4, borderRadius: 5, width: '100%', }}>
            <Box >
              <Typography color='#DD952B' fontWeight={600} fontSize={26}>
                01. Office Essentials
              </Typography >
              <Typography fontWeight={400} fontSize={18}>
                Equip your team with the tools they need to succeed in the modern workplace. Our office essentials include
              </Typography>
            </Box>
            <Box position="relative" height='75vh' display='flex' justifyContent="center" alignItems='center'>
              <Box sx={{ position: "absolute", width: 200, right: 0, height: '100%', borderTopLeftRadius: 30, borderBottomLeftRadius: 30, backgroundColor: 'rgb(from #4672E3 r g b / 30%)' }} />
              <SliderMarch slideData={OfficeEssentialsData} />
            </Box>
          </Box>
          <Box sx={{ my: 5, py: 5, px: 4, borderRadius: 5, }}>
            <Box pb={10}>
              <Typography color='#202020' fontWeight={600} fontSize={26} textAlign='right' pb={2}>
                02. E-Gadgets
              </Typography >
              <Typography fontWeight={400} fontSize={18} textAlign='right' >
                Stay ahead of the curve with our pioneering e-gadgets designed to enhance productivity and convenience. Our e-gadgets collection features
              </Typography>
            </Box>
            <Box position="relative" height='75vh' display='flex' justifyContent="center" alignItems='center'>
              <Box sx={{ position: "absolute", width: 200, left: 0, height: '100%', borderStartEndRadius: 30, borderEndEndRadius: 30, backgroundColor: 'rgb(from #F8A721 r g b / 30%)' }} />
              <SliderMarch slideData={EGadgets} sx={{ flexDirection: 'row-reverse', mx: 5 }} />
            </Box>
          </Box>
          <Box sx={{ my: 5, py: 10, px: 4, borderRadius: 5, }}>
            <Box >
              <Typography color='#202020' fontWeight={600} fontSize={26}>
                03. Apparels
              </Typography >
              <Typography fontWeight={400} fontSize={18}>
                Elevate your team's style with our range of premium apparel options. Whether it's a casual Friday or a corporate event, Klues has you covered with
              </Typography>
            </Box>
            <Box position="relative" height='75vh' display='flex' justifyContent="center" alignItems='center'>
              <Box sx={{ position: "absolute", width: 200, right: 0, height: '100%', borderTopLeftRadius: 30, borderBottomLeftRadius: 30, backgroundColor: 'rgb(from #EF4136 r g b / 30%)' }} />
              <SliderMarch slideData={ApparelsData} />
            </Box>
          </Box>

          <Box>
            <Typography fontWeight={600} fontSize={40}>Our ISP</Typography>
            <Typography color='#DD952B' fontWeight={500} fontSize={20}>
              Why Choose Klues for Your Corporate Merchandise Needs?
            </Typography>
            <Box ref={cardRef} sx={{ height: '45vh', overflow: 'hidden', position: 'relative', }} >
              <Box sx={{ opacity: 1, transition: 'opacity 0.5s', }} >
                <QualityAssurance onScroll={handleScroll} />
              </Box>
            </Box>
          </Box>

        </Container>
      </Layout>
    </div>
  );
};

export default Merchandise;

import React from 'react'
import Layout from '../../component/Layout'
import { Box, Container } from '@mui/material'
import SubHeader from '../../component/SubHeader'
import { efficientTop } from '../../data/SubHeaderData'
import ServiceIntro from '../../component/ServiceIntro'
import { efficientIntro } from '../../data/serviceHead'
import ServiceCard2 from '../../component/ServiceCard2'
import { efficient } from '../../data/subServiceData'
import  bottamEfficient from "../../asset/images/bottamBnrImgs/bottamEfficient.jpg";

const EfficientAutomation = () => {
  return (
    <Layout>
    <Container
      sx={{ maxWidth: { lg: "lg", xl: "xl", md: "md", sm: "sm", xs: "xs" } }}
    >
      <Box sx={{ mt: 15 }}>
        <SubHeader data={efficientTop} />
      </Box>

      <Box>
        <ServiceIntro data={efficientIntro} />
      </Box>
      <Box pb={5}>
        <ServiceCard2 data={efficient} />
      </Box>
      <Box sx={{ textAlign: "center" }} py={5}>
          <img src={bottamEfficient} width="100%" style={{borderRadius:"35px"}} />
        </Box>
    </Container>
  </Layout>
  )
}

export default EfficientAutomation
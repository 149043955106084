import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Modal,
  TextField,
  Typography,
  colors,
} from "@mui/material";
import { HiArrowLongRight } from "react-icons/hi2";
import axios from "axios";
import styled from "@emotion/styled/macro";
import ModalConfirm from "../utills/ModalConfirm";

const ContactForm = () => {
  const aboutUs = [
    {
      value: "Other",
      label: "Other",
    },
    {
      value: "google_search",
      label: "Google Search",
    },
    {
      value: "friends_referal",
      label: "Friends Referral",
    },
    {
      value: "social_media",
      label: "Social Media",
    },
  ];

  const [formdata, setFormdata] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    message: "",
  });
  const [showModal, setShowModal] = useState(false);

  const handleInputChange = (name) => (e) => {
    let value = e.target ? e.target.value : e;
    setFormdata({ ...formdata, [name]: value });
  };

  const addContact = async () => {
    try {
      const payload = {
        firstname: formdata.firstname,
        lastname: formdata.lastname,
        email: formdata.email,
        phone: formdata.phone,
        message: formdata.message,
      };

      await axios
        .post("https://klues.co.in/backend/api/query/add", payload)
        .then((resp) => {
          setShowModal(true);
          setFormdata({
            firstname: "",
            lastname: "",
            email: "",
            phone: "",
            message: "none",
          });
        })
        .catch((error) => {
          console.error("Error:", error.message);
        });
    } catch (err) {
      console.error("Error:", err.message);
    }
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            id="firstname"
            name="firstname"
            label="First Name"
            variant="standard"
            color="success"
            placeholder="First Name"
            required
            onChange={handleInputChange("firstname")}
            value={formdata.firstname}
            sx={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            id="lastname"
            name="lastname"
            label="Last Name"
            variant="standard"
            color="success"
            placeholder="Last Name"
            onChange={handleInputChange("lastname")}
            value={formdata.lastname}
            sx={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            id="email"
            name="email"
            label="E-Mail"
            variant="standard"
            color="success"
            placeholder="e.g. example@absax.com"
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
            required
            sx={{ width: "100%" }}
            error={
              !!formdata.email &&
              !/^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/.test(
                formdata.email
              )
            }
            helperText={
              formdata.email &&
              !/^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/.test(
                formdata.email
              ) ? (
                <span style={{ color: "#b10004" }}>Invalid email format</span>
              ) : (
                ""
              )
            }
            onChange={handleInputChange("email")}
            value={formdata.email}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            id="phone"
            name="phone"
            label="Phone"
            variant="standard"
            color="success"
            placeholder="e.g. 087482445"
            pattern="^\d{10}$"
            required
            error={!!formdata.phone && !/^\d{10}$/.test(formdata.phone)}
            helperText={
              formdata.phone && !/^\d{10}$/.test(formdata.phone)
                ? "Invalid phone number format"
                : ""
            }
            onChange={handleInputChange("phone")}
            value={formdata.phone}
            sx={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            id="message"
            name="message"
            select
            label="How did you hear about us"
            color="success"
            variant="standard"
            required
            onChange={handleInputChange("message")}
            value={formdata.message}
            defaultValue="none"
            sx={{ width: "100%" }}
          >
            {aboutUs.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <br />
      <Typography sx={{ fontSize: 16 }} py={2}>
        By sending this I confirm that I have read and accept the{" "}
        <b>privacy policy</b>
      </Typography>{" "}
      <Box py={2}>
        <Button
          onClick={addContact}
          sx={{
            borderRadius: "20px",
            fontWeight: "bold",
          }}
          endIcon={<HiArrowLongRight />}
          variant="contained"
          color="success"
          size="medium"
        >
          Submit
        </Button>
      </Box>
      <ModalConfirm
        text1={"Query"}
        open={showModal}
        close={() => setShowModal(false)}
      />
    </div>
  );
};

export default ContactForm;

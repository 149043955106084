import watch1 from '../asset/images/Merchandise/Smartwatch/wathc1.png'
import watch2 from '../asset/images/Merchandise/Smartwatch/watch2.png'
import watch3 from '../asset/images/Merchandise/Smartwatch/watch3.jpg'

import headphone1 from '../asset/images/Merchandise/Headphones/headphone1.png'

import earbuds1 from '../asset/images/Merchandise/Earbuds/earbud1.png'
import earbuds2 from '../asset/images/Merchandise/Earbuds/earbud2.png'
import earbuds3 from '../asset/images/Merchandise/Earbuds/earbud3.png'

import powerbank1 from '../asset/images/Merchandise/Powerbanks/powerbank1.png'
import powerbank2 from '../asset/images/Merchandise/Powerbanks/powerbank2.png'
import powerbank3 from '../asset/images/Merchandise/Powerbanks/powerbank3.jpg'

import neckband1 from '../asset/images/Merchandise/Neckbands/neckband1.png'

import speaker1 from '../asset/images/Merchandise/Speakers/speaker1.png'


export const EGadgets = [
  {
    id: 1,
    head: "Smart Watch",
    text: "Stay connected and track your fitness goals with our stylish smartwatches.",
    image: [
      { id: 1, image: watch1 },
      { id: 2, image: watch2 },
      { id: 3, image: watch3 },
    ],
  },
  {
    id: 2,
    head: "Headphones",
    text: "Immerse yourself in crystal-clear sound with our premium headphones.",
    image: [
      { id: 1, image: headphone1 },
    ],
  },
  {
    id: 3,
    head: "Earbuds",
    text: "Enjoy wireless freedom and superior audio quality with our sleek earbuds. ",
    image: [
      { id: 1, image: earbuds1 },
      { id: 2, image: earbuds2 },
      { id: 3, image: earbuds3 },
    ],
  },
  {
    id: 4,
    head: "Power Bank",
    text: " Never run out of battery power with our portable and efficient power banks. ",
    image: [
      { id: 1, image: powerbank1 },
      { id: 2, image: powerbank2 },
      { id: 3, image: powerbank3 },
    ],
  },
  {
    id: 5,
    head: "Neckbands",
    text: "Experience comfort and convenience on the go with our lightweight neckbands. ",
    image: [
      { id: 1, image: neckband1 },
    ],
  },
  {
    id: 6,
    head: "Mini Bluetooth Speakers",
    text: "Make a statement with our elegant and ergonomic pens.",
    image: [
      { id: 1, image: speaker1 },
    ],
  },
];

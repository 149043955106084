import React, { useRef, useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Container, Divider, Grid, Paper, Stack, Typography } from '@mui/material';
import { HiArrowLongRight } from "react-icons/hi2";
import qualityImage from '../asset/images/Merchandise/qualityimage.png';
import Customization from '../asset/images/Merchandise/customization.png';
import Variety from '../asset/images/Merchandise/variety.png';
import Convenience from '../asset/images/Merchandise/convinence.png';
import Customer from '../asset/images/Merchandise/customer.png';

const QualityAssurance = ({ onScroll }) => {
    const assurance = [
        {
            id: 1,
            image: qualityImage,
            head: 'Quality Assurance',
            text: 'We take pride in delivering only the highest quality merchandise, ensuring durability and satisfaction.',
        },
        {
            id: 2,
            image: Customization,
            head: 'Customization',
            text: 'Make your brand stand out with our customizable options, allowing you to add your logo or branding to any product.',
        },
        {
            id: 3,
            image: Variety,
            head: 'Variety',
            text: 'With a wide range of products to choose from, we cater to diverse corporate needs and preferences.',
        },
        {
            id: 4,
            image: Convenience,
            head: 'Convenience',
            text: 'Our user-friendly ordering process and prompt delivery ensure a seamless experience from start to finish.',
        },
        {
            id: 5,
            image: Customer,
            head: 'Customer Service',
            text: 'Have questions or need assistance? Our dedicated support team is here to help every step of the way.',
        },
    ];

    const sliderRef = useRef(null);
    const [currentSlide, setCurrentSlide] = useState(0);

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        vertical: true,
        beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
    };

    useEffect(() => {
        const handleWheel = (event) => {
            if (event.deltaY > 0) {
                sliderRef.current.slickNext();
            } else {
                sliderRef.current.slickPrev();
            }
        };

        const handleScroll = (event) => {
            if (currentSlide !== 0 && currentSlide !== assurance.length - 1) {
                event.preventDefault();
            }
            onScroll(currentSlide, assurance.length);
        };

        const sliderElement = sliderRef.current.innerSlider.list;
        sliderElement.addEventListener('wheel', handleWheel);
        window.addEventListener('scroll', handleScroll, { passive: false });

        return () => {
            sliderElement.removeEventListener('wheel', handleWheel);
            window.removeEventListener('scroll', handleScroll);
        };
    }, [currentSlide, assurance.length, onScroll]);

    return (
        <Box sx={{
            boxSizing: "border-box",
            height: "calc(100% - 5px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
            "&::-webkit-scrollbar": {
                width: 0,
                background: "transparent",
            },
        }}>
            <Slider {...settings} ref={sliderRef}>
                {assurance.map((item, index) => (
                    <Box key={index} height={"40vh"} width='100%' display='flex' alignItems='center' justifyContent='center'>
                        <Box display='flex' alignItems='center' position='relative' justifyContent='center' height="100%">
                            <Divider sx={{ borderColor: '#E2E2E2', borderWidth: 2, zIndex: 0, position: "absolute",height:'100%' }} orientation="vertical" />
                            <Grid container columnSpacing={2} sx={{ flexDirection: index % 2 === 0 ? "row" : "row-reverse", zIndex: 2 }} justifyContent='center' alignItems='center'>
                                <Grid item xs={12} md={4.5}>
                                    <Stack alignItems='center' height='100%' width='80%'>
                                        <Typography fontSize={26} color="#4672E3">{item.head}</Typography>
                                        <Typography textAlign='justify'>
                                            {item.text}
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={2.5}>
                                    <Box component='img' src={item.image} sx={{ height: { xs: 200, md: 250 }, width: { xs: 200, md: 250 }, borderRadius: '50%',margin:'auto' }} />
                                </Grid>
                                <Grid item xs={12} md={4.5}>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                ))}
            </Slider>
        </Box>
    );
};

export default QualityAssurance;

import { Button } from '@mui/material'
import React from 'react'
import { HiArrowLongRight } from 'react-icons/hi2'

const LearnMoreBtn = () => {
  return (
    <div>
         <Button 
          sx={{
            px: 3,
            py:2,
            fontWeight: "bold",
            borderRadius: "5px",
          }}
          component="a"
          href="/recruitment-services"
          target="_blank"
          size="medium"
          color="primary"
          variant="outlined"
          endIcon={
            <HiArrowLongRight/>
          }
        >
            Learn More
        </Button>
    </div>
  )
}

export default LearnMoreBtn
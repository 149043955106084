export const PrivacyData = {
  points: [
    "Before or at the time of collecting personal information, we will identify the purposes for which information is being collected.",
    "We will gather and utilization of individual data singularly with the target of satisfying those reasons indicated by us and for other good purposes, unless we get the assent of the individual concerned or as required by law.",
    "We will just hold individual data the length of essential for the satisfaction of those reasons.",
    "We will gather individual data by legal and reasonable means and, where fitting, with the information or assent of the individual concerned.",
    "Personal information ought to be important to the reasons for which it is to be utilized, and, to the degree essential for those reasons, ought to be exact, finished, and updated.",
    "We will protect individual data by security shields against misfortune or burglary, and also unapproved access, divulgence, duplicating, use or alteration.",
    "We will promptly provide customers with access to our policies and procedures for the administration of individual data.",
  ],
  bottamText:
    "We are focused on leading our business as per these standards with a specific end goal to guarantee that the privacy of individual data is secure and maintained.",
};

export const TermsData = [
  {
    heading: "Acceptance of Terms",
    points: [
      "By accessing and using this website, you agree to comply with and be bound by these terms and conditions. It states that using the website is conditional on accepting and complying with these Terms. Nothing contained herein shall be deemed to confer any third-party rights or benefits. In the event you do not agree to be bound by these Terms of Use, you may not use nor access this Website.",
    ],
  },
  {
    heading: "Change of Terms",
    points: [
      "We reserve the right to modify these terms and conditions at any time. Any changes will be effective immediately upon posting. It is your responsibility to review these terms periodically for updates.",
    ],
  },
  {
    heading: "Use of Websites",
    points: [
      "This Website, under the name and style “www.klues.co.in” is an online medium that has been designed to enable visitors, including Current or Potential Customers, Alliance Partners or Potential Alliance Partners, Financial and Industry Analysts, Investors, Vendors / Suppliers, Media & Journalists, Job seekers, Current and Former employees, Researchers or Academicians, and others, to gather information about Klues and to interact with Klues by sharing their queries through the website contact forms. ",
      "You may use this website for informational purposes and for engaging with our services, subject to these terms. ",
      "You must not use this website for any unlawful or prohibited activities. ",
    ],
  },
  {
    heading: "Intellectual Property ",
    points: [
      "All content on this website, including but not limited to text, graphics, logos, images, and software, is the property of Klues Services Pvt Ltd and is protected by copyright and other intellectual property laws.",
      "You may not reproduce, distribute, modify, or create derivative works from any content on this website without our express written consent.",
      "Any use in any manner of manual or automated software, devices, techniques, or other processes/procedures to “crawl” or “spider” any web pages contained in this Website is strictly prohibited. You understand and agree not to supervise, monitor copy, or allow others to supervise, monitor, or copy web pages or the content of this Website. You understand and agree not to frame edit or otherwise replicate the appearance features or functions of this Website. You understand and agree not to take any action or allow anyone to do so that hinders obstructs or interferes with the working of or places an inappropriate load on our infrastructure to host this Website.",
    ],
  },
  {
    heading: "Links to Third-Party Websites",
    points: [
      "This website may contain links to third-party websites. These links are provided for your convenience only. We have no control over the content of third-party websites and are not responsible for their content or actions.",
    ],
  },
  {
    heading: "Disclaimer",
    points: [
      "This website and its content are provided '    as is' without any warranties, express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, or non-infringement.",
      "We do not guarantee the accuracy, completeness, or timeliness of the information provided on this website. ",
    ],
  },
  {
    heading: "Limitation of Liability ",
    points: [
      "Klues Services and its officers, directors, employees, or agents shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way related to your use of this website. ",
    ],
  },
  {
    heading: "Governing Law",
    points: [
      "These terms and conditions are governed by and construed in accordance with the laws of India. Any disputes relating to these terms and conditions will be subject to the exclusive jurisdiction of the courts in India. ",
    ],
  },
  {
    heading: "Contact Information ",
    points: [
      "If you have any questions or concerns about these terms and conditions, please contact us at 'hr@Klues.co.in'.",
      "By using our website, you acknowledge that you have read, understood, and agreed to these terms and conditions.",
    ],
  },
];

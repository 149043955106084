import React, { useState } from "react";
import {
  IconButton,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Collapse,
  Divider,
  Box,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { Link } from "react-router-dom";
import logo from "../asset/images/logo.png";

const MobileNavbar = ({ navTitle }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openNestedList, setOpenNestedList] = useState(
    new Array(navTitle.length).fill(false)
  );

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const handleNestedListToggle = (index) => {
    const newOpenNestedList = [...openNestedList];
    newOpenNestedList[index] = !newOpenNestedList[index];
    setOpenNestedList(newOpenNestedList);
  };

  return (
    <>
      <IconButton
        size="large"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        color="inherit"
        sx={{ ml: { sm: 5, xs: 10 } }}
      >
        <MenuIcon sx={{ fontSize: "30px" }} />
      </IconButton>

      <Drawer anchor="left" open={openDrawer} onClose={handleDrawerClose}>
        <Box sx={{ padding: "15px 0px 10px 15px" }}>
          <Link to="/">
            <img src={logo} alt="logo" width="190px" />
          </Link>
        </Box>
        <List sx={{ width: "300px !important" }}>
          {navTitle.map((item, index) => (
            <React.Fragment key={index}>
              <ListItemButton onClick={() => handleNestedListToggle(index)}>
                <ListItemIcon>
                  {item.icon}
                </ListItemIcon>
                {item.menu && item.menu.length > 0 ? (
                  <ListItemText primary={item.title} />
                ) : (
                  <Link
                    to={item.link}
                    style={{
                      textDecoration: "none",
                      color: "black",
                      fontSize: "18px",
                    }}
                  >
                    <ListItemText  primary={item.title} />
                  </Link>
                )}
                {item.menu &&
                  item.menu.length > 0 && // Check if menu exists and has items
                  (openNestedList[index] ? (
                    <ExpandLessOutlinedIcon />
                  ) : (
                    <ExpandMoreOutlinedIcon />
                  ))}
              </ListItemButton>
              <Divider />

              {item.menu &&
                item.menu.length > 0 && ( // Check if menu exists and has items
                  <Collapse
                    in={openNestedList[index]}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div">
                      {item.menu.map((menuListItem, menuIndex) => (
                        <Link
                          to={menuListItem.link}
                          style={{
                            textDecoration: "none",
                            color: "#E89B0E",
                          }}
                        >
                          <ListItemButton key={menuIndex} sx={{ pl: 4 }}>
                            <ListItemText  sx={{ pl: 5}} primary={menuListItem.title} />
                            
                          </ListItemButton>
                          <Divider />
                        </Link>
                      ))}
                    </List>
                  </Collapse>
                )}
            </React.Fragment>
          ))}
        </List>
       
      </Drawer>
    </>
  );
};

export default MobileNavbar;

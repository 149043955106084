import {
  Box,
  Container,
  Divider,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import Layout from "../component/Layout";
import { TermsData } from "../data/TermsPrivacyData";
import StarBorderPurple500Icon from "@mui/icons-material/StarBorderPurple500";

const Terms = () => {
  return (
    <Layout>
      <div>
        <Container
          sx={{
            maxWidth: { lg: "lg", xl: "xl", md: "md", sm: "sm", xs: "xs" },
          }}
        >
          <Box mt={15}>
            <Grid container spacing={2} py={5}>
              <Grid item lg={5} md={8} xs={12}>
                <Typography
                  color="primary"
                  sx={{
                    fontSize: { lg: 45, md: 30, xs: 26 },
                    fontWeight: "bold",
                  }}
                >
                  Terms & Conditions
                </Typography>
              </Grid>
              <Grid item lg={7} md={6} xs={12}>
                {" "}
                <Typography
                  sx={{
                    fontSize: { lg: 20, md: 18, xs: 16 },
                    textAlign: "justify",
                  }}
                >
                  Welcome to Klues! These Terms of Use ("Terms") outline the
                  conditions for using the Klues platform, encompassing all
                  content, features, and services available on or through Klues
                  (collectively referred to as the "Services"). Before using the
                  Services, please review these Terms attentively.
                </Typography>
              </Grid>
            </Grid>

            <Divider />

            <Box py={5}>
              {TermsData.map((data, index) => (
                <Box index={index} py={2}>
                  <Typography
                    sx={{
                      fontSize: { lg: 26, md: 24, xs: 22 },
                      fontWeight: 500,
                    }}
                  >
                    {data.heading}
                  </Typography>
                  {data.points.map((point, index) => (
                    <ListItem sx={{ position: "relative" }}>
                      <ListItemIcon
                        sx={{ position: "absolute", top: 15, left: 0 }}
                      >
                        <StarBorderPurple500Icon
                          sx={{
                            color: "primary.main",
                            fontSize: { lg: 25, md: 22, xs: 20 },
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{
                          style: {
                            fontSize: 20,
                            marginLeft: "1.5em",
                          },
                        }}
                        component="typography"
                        primary={point}
                      />
                    </ListItem>
                  ))}
                </Box>
              ))}
            </Box>
          </Box>
        </Container>
      </div>
    </Layout>
  );
};

export default Terms;

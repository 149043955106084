import React, { useState } from "react";
import {
  Typography,
  Grid,
  Input,
  Card,
  Box,
  Button,
  FormLabel,
  FormGroup,
  FormHelperText,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import referImg from "../asset/images/formRefer.png";
import ModalConfirm from "../utills/ModalConfirm";
import axios from "axios";

const ReferForm = () => {
  const aboutUs = [
    {
      value: "None",
      label: "None",
    },
    {
      value: "Hr",
      label: "Hr",
    },
    {
      value: "Finance",
      label: "Finance",
    },
    {
      value: "Technology",
      label: "Technology",
    },
    {
      value: "Sales Marketing",
      label: "Sales Marketing",
    },
    {
      value: "Other",
      label: "Other",
    },
  ];

  const [formdata, setFormdata] = useState({
    Referral_firstname: "",
    Referral_lastname: "",
    Referral_email: "",
    Referral_phone: "",
    Referral_department: "None",
    Your_firstname: "",
    Your_lastname: "",
    Your_email: "",
    Your_phone: "",
  });
  const [showModal, setShowModal] = useState(false);

  const handleInputChange = (name) => (e) => {
    let value = e.target ? e.target.value : e;
    setFormdata({ ...formdata, [name]: value });
  };

  const addReferral = async () => {
    try {
      const payload = {
        Referral_firstname: formdata.Referral_firstname,
        Referral_lastname: formdata.Referral_lastname,
        Referral_email: formdata.Referral_email,
        Referral_phone: formdata.Referral_phone,
        Referral_department: formdata.Referral_department,
        Your_firstname: formdata.Your_firstname,
        Your_lastname: formdata.Your_lastname,
        Your_email: formdata.Your_email,
        Your_phone: formdata.Your_phone,
        Your_phone: formdata.Your_phone,
      };

      await axios
        .post("https://klues.co.in/backend/api/reference/add", payload)
        .then((resp) => {
          setShowModal(true);
          setFormdata({
            Referral_firstname: "",
            Referral_lastname: "",
            Referral_email: "",
            Referral_phone: "",
            Referral_department: "None",
            Your_firstname: "",
            Your_lastname: "",
            Your_email: "",
            Your_phone: "",
          });
     
        })
        .catch((error) => {
          console.error("Error:", error.message);
        });
    } catch (err) {
      console.error("Error:", err.message);
    }
  };

  return (
    <div>
      <Grid container spacing={5}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Grid container spacing={5}>
            <Grid item lg={12} md={12} sm={6} xs={12}>
              <img src={referImg} alt="" style={{ maxWidth: "100%" }} />
            </Grid>
            <Grid item lg={12} md={12} sm={6} xs={12}>
              <Typography color={"#686565"} width="88%">
                Empower your network by sharing career opportunities. Refer your
                friends to unlock exciting prospects and contribute to shaping
                success stories with us.​
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Card
            sx={{
              border: "1px solid #DD952B",
              padding: "30px",
              borderRadius: "26px",
            }}
          >
            <Box
              sx={{
                display: {
                  xs: "block",
                  md: "flex",
                  sm: "flex",
                  lg: "flex",
                },
                justifyContent: "space-between",
              }}
              py={5}
            >
              <FormGroup>
                <FormLabel style={{ color: "black" }}>
                  Referral First Name
                </FormLabel>
                <Input
                  placeholder="e.g. James"
                  sx={{
                    borderBottom: "1px solid black",
                  }}
                  color="secondary"
                  onChange={handleInputChange("Referral_firstname")}
                  name="Referral_firstname"
                  value={formdata.Referral_firstname}
                />
              </FormGroup>
              <FormGroup>
                <FormLabel style={{ color: "black" }}>
                  Referral Last Name
                </FormLabel>
                <Input
                  placeholder="e.g. Anderson"
                  sx={{
                    borderBottom: "1px solid black",
                  }}
                  color="secondary"
                  onChange={handleInputChange("Referral_lastname")}
                  name="Referral_lastname"
                  value={formdata.Referral_lastname}
                />
              </FormGroup>
            </Box>
            <Box
              sx={{
                display: {
                  xs: "block",
                  md: "flex",
                  sm: "flex",
                  lg: "flex",
                },
                justifyContent: "space-between",
              }}
              pb={5}
            >
              <FormGroup>
                <FormLabel style={{ color: "black" }}>
                  Referral E-Mail
                </FormLabel>
                <Input
                  color="secondary"
                  placeholder="e.g. James@gmail.com"
                  sx={{
                    borderBottom: "1px solid black",
                    width: "100%",
                  }}
                  onChange={handleInputChange("Referral_email")}
                  name="Referral_email"
                  value={formdata.Referral_email}
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                  required
                  error={
                    !!formdata.Referral_email &&
                    !/^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/.test(
                      formdata.Referral_email
                    )
                  }
                ></Input>
                <FormHelperText sx={{ color: "red" }}>
                  {formdata.Referral_email &&
                  !/^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/.test(
                    formdata.Referral_email
                  ) ? (
                      " * Invalid email format"
                  ) : (
                    ""
                  )}
                </FormHelperText>
              </FormGroup>
              <FormGroup>
                <FormLabel style={{ color: "black" }}>
                  {" "}
                  Referral Phone
                </FormLabel>
                <Input
                  color="secondary"
                  placeholder="e.g. 087482445"
                  sx={{
                    borderBottom: "1px solid black",
                    width: "100%",
                  }}
                  onChange={handleInputChange("Referral_phone")}
                  name="Referral_phone"
                  value={formdata.Referral_phone}
                  pattern="^\d{10}$"
                  required
                  error={!!formdata.Referral_phone && !/^\d{10}$/.test(formdata.Referral_phone)}
                ></Input>
                 <FormHelperText sx={{ color: "red" }}>
                  {formdata.Referral_phone && !/^\d{10}$/.test(formdata.Referral_phone)
                    ? " * Invalid phone number formate"
                    : ""}
                </FormHelperText>
              </FormGroup>
            </Box>
            <FormLabel style={{ color: "black" }}>
              Referral Department
            </FormLabel>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "60%" },
              }}
              py={2}
              noValidate
              autoComplete="off"
            >
              <TextField
                select
                defaultValue="EUR"
                SelectProps={{
                  native: true,
                }}
                variant="standard"
                color="secondary"
                  onChange={handleInputChange("Referral_department")}
                  name="Referral_department"
                value={formdata.Referral_department}
              >
                {aboutUs.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Box>

            <Box
              sx={{
                display: {
                  xs: "block",
                  md: "flex",
                  sm: "flex",
                  lg: "flex",
                },
                justifyContent: "space-between",
              }}
              py={4}
            >
              <FormGroup>
                <FormLabel style={{ color: "black" }}>
                  Your First Name
                </FormLabel>
                <Input
                  color="secondary"
                  placeholder="e.g. James"
                  sx={{
                    borderBottom: "1px solid black",
                  }}
                  onChange={handleInputChange("Your_firstname")}
                  name="Your_firstname"
                  value={formdata.Your_firstname}
                ></Input>
              </FormGroup>
              <FormGroup>
                <FormLabel style={{ color: "black" }}>Your Last Name</FormLabel>
                <Input
                  color="secondary"
                  placeholder="e.g. Anderson"
                  sx={{
                    borderBottom: "1px solid black",
                  }}
                  onChange={handleInputChange("Your_lastname")}
                  name="Your_lastname"
                  value={formdata.Your_lastname}
                ></Input>
              </FormGroup>
            </Box>
            <Box
              sx={{
                display: {
                  xs: "block",
                  md: "flex",
                  sm: "flex",
                  lg: "flex",
                },
                justifyContent: "space-between",
              }}
            >
              <FormGroup>
                <FormLabel style={{ color: "black" }}>Your E-Mail</FormLabel>
                <Input
                  color="secondary"
                  placeholder="e.g. James@gmail.com"
                  sx={{
                    borderBottom: "1px solid black",
                    width: "100%",
                  }}
                  onChange={handleInputChange("Your_email")}
                  name="Your_email"
                  value={formdata.Your_email}
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                  required
                  error={
                    !!formdata.Your_email &&
                    !/^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/.test(
                      formdata.Your_email
                    )
                  }
                ></Input>
                <FormHelperText sx={{ color: "red" }}>
                  {formdata.Your_email &&
                  !/^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/.test(
                    formdata.Your_email
                  ) ? (
                      " * Invalid email format"
                  ) : (
                    ""
                  )}
                </FormHelperText>
              </FormGroup>
              <FormGroup>
                <FormLabel style={{ color: "black" }}>Your Phone</FormLabel>
                <Input
                  color="secondary"
                  placeholder="e.g. 087482445"
                  sx={{
                    borderBottom: "1px solid black",
                    width: "100%",
                  }}
                  onChange={handleInputChange("Your_phone")}
                  name="Your_phone"
                  value={formdata.Your_phone}
                  pattern="^\d{10}$"
                  required
                  error={!!formdata.Your_phone && !/^\d{10}$/.test(formdata.Your_phone)}
                ></Input>
                 <FormHelperText sx={{ color: "red" }}>
                  {formdata.Your_phone && !/^\d{10}$/.test(formdata.Your_phone)
                    ? " * Invalid phone number formate"
                    : ""}
                </FormHelperText>
              </FormGroup>
            </Box>
            <Box pb={3} pt={5}>
              <Button
                sx={{
                  border: "1px solid #DD952B",
                  borderRadius: "10px",
                }}
                endIcon={<TrendingFlatIcon />}
                variant="outlined"
                color="secondary"
                size="large"
                onClick={addReferral}
              >
                Send
              </Button>
            </Box>
          </Card>
        </Grid>
      </Grid>

      <ModalConfirm text1={"Referral"} open={showModal} close={() => setShowModal(false)} />
    </div>
  );
};

export default ReferForm;

import React, { useState, useRef } from "react";
import Slider from "react-slick";
import { Box, Stack, Typography, Button, Grid, Avatar } from "@mui/material";
import { IoIosArrowRoundForward } from "react-icons/io";
import MarchImageSlider from "./MarchImageSlider";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const SliderMarch = ({slideData,flexDirection,sx}) => {
  const sliderRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(slideData[0].image[0].image);
  const [currentSlide, setCurrentSlide] = useState(0);

  const handlePrevious = () => {
    sliderRef.current.slickPrev();
  };

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleSlideChange = (oldIndex, newIndex) => {
    setSelectedImage(slideData[newIndex].image[0].image);
    setCurrentSlide(newIndex);
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    beforeChange: handleSlideChange,
    responsive: [
      {
        settings: {
          vertical: false,
          verticalSwiping: true,
        },
      },
    ],
  };

  return (
    <Box width="100%" position='absolute'>
      <Stack direction="row" justifyContent="center" spacing={1} py={2}>
        {slideData.map((_, index) => (
          <Box
            key={index}
            onClick={() => sliderRef.current.slickGoTo(index)}
            sx={{
              cursor: 'pointer',
              bgcolor: currentSlide === index ? '#4672E3' : '#7D7D7D',
              height: 5,
              borderRadius:10,
              width: currentSlide === index ? 39 : 9,
              transition: 'width 0.3s',
            }}
          />
        ))}
      </Stack>
      <Slider {...settings} ref={sliderRef}>
        {slideData.map((step, index) => (<>
          <Grid container justifyContent="center" py={5} spacing={2} alignItems="center" pt={1} sx={sx}>
            <Grid item lg={6} textAlign="center">
              <Typography fontSize={60} fontWeight={600} color="#4672E3" pb={2}>
                {step.head}
              </Typography>
              <Typography pb={10}>
                {step.text}
              </Typography>
              <Stack direction="row" justifyContent="center" gap={5}>
                <Button
                  variant="contained"
                  sx={{ borderRadius: 10, textTransform: "none" }}
                  endIcon={<IoIosArrowRoundForward />}
                >
                  Shop Now
                </Button>
                <Button type="text" sx={{ textTransform: "none" }}>
                  Request a quote
                </Button>
              </Stack>
            </Grid>
            <Grid item lg={6}>
              <Box position="relative">
                <Box component="img" src={selectedImage} alt=""  height={500} maxWidth={"100%"} borderRadius={5}/>
                <Box sx={{ position: "absolute", bottom: 20, left: 20 }}>
                  <MarchImageSlider 
                    data={step.image}
                    handleImageClick={handleImageClick}
                    selectedImage={selectedImage}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </>))}
      </Slider>
      <Stack direction="row" gap={5} justifyContent="center">
        <Avatar
          onClick={handlePrevious}
          sx={{
            textAlign: "center",
            cursor: 'pointer',
            color: '#fff',
            bgcolor: '#4672E3',
            visibility: currentSlide === 0 ? 'hidden' : 'visible',
          }}
        >
          <ArrowBackIosIcon />
        </Avatar>
        <Avatar
          onClick={handleNext}
          sx={{
            textAlign: "center",
            cursor: 'pointer',
            color: '#fff',
            bgcolor: '#4672E3',
            visibility: currentSlide === slideData.length - 1 ? 'hidden' : 'visible',
          }}
        >
          <ArrowForwardIosIcon />
        </Avatar>
      </Stack>
    </Box>
  );
};
export default SliderMarch;
import * as React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import {
  Box,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { jobcardData } from "../../data/JobCardData";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(0),

  color: theme.palette.text.secondary,
}));

export default function Jobcard() {
  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Grid container spacing={5}>
        {jobcardData.map((item1) => (
          <>
            <Grid item lg={6} xs={12} sm={12} md={6} textAlign={"center"} >
              <Paper sx={{height:"100%", backgroundColor:"rgb(48, 90, 200,0.05)",borderRadius:"30px"
                  ,"&:hover": {
                    background: "rgba(48, 90, 200, 0.08)",
                    "& .heading, & .MuiButton-root": {
                      color: "#305AC8",
                    }
                  }}}
                  elevation={0}
                  
                  >
                <Box pt={6}>
                  <img src={item1.image} style={{maxWidth:"100%"}}/>
                </Box>
                <List>
                  <ListItem alignItems="flex-start">
                    <ListItemIcon sx={{ minWidth: "30px" }}>
                      <img src="star.png"  width={30} style={{alignItems:'start' , p:2}} />
                    </ListItemIcon>
                    <ListItemText ><Typography className="heading" sx={{fontWeight:"bold",fontSize:{md:25,xs:20}}}>{item1.head}</Typography></ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText sx={{fontSize:{md:16,xs:14},color:"GrayText",overflow:"hidden",}}>{item1.text}</ListItemText>
                  </ListItem>
                </List>
                <div style={{ display:"flex", justifyContent: "flex-end" }}>
                  <Button size="Large" component="a" href="/about-us"  target="_blank" endIcon={ <ArrowRightAltIcon />}  sx={{fontWeight:"bold", p:2, mt:5, bgcolor:'white' ,color:'black', borderStartStartRadius:"15px",'&:hover': {
              backgroundColor: '#ffffff',
            }}}>
                    learn more
                  </Button>
                </div>
              </Paper>
            </Grid>
            
          </>
        ))}
      </Grid>
    </Container>
  );
}

import React from "react";
import SubHeader from "../../component/SubHeader";
import { Box, Typography } from "@mui/material";
import { Container } from "@mui/material";
import { Discover } from "../../data/SubHeaderData";
import bottamTalent from "../../asset/images/bottamBnrImgs/bottamTalent.png";
import WhyKlueCard from "../../component/WhyKlueCard";
import TalentCard from "../../component/TalentCard";
import ServiceIntro from "../../component/ServiceIntro";
import { recruitmentIntro } from "../../data/serviceHead";
import Layout from "../../component/Layout";

const TalentAcquisition = () => {
  return (
    <Layout>
      <Container
        sx={{ maxWidth: { lg: "lg", xl: "xl", md: "md", sm: "sm", xs: "xs" } }}
      >
        <Box sx={{ mt: 15 }}>
          <SubHeader data={Discover} />
        </Box>

        <Box py={5}>
          <ServiceIntro data={recruitmentIntro} />
        </Box>

        <Box py={5}>
          <Typography
            whiteSpace={"wrap"}
            fontWeight={600}
            sx={{
              fontSize: { lg: 40, md: 32, xs: 20 },
            }}
            pl={1}
          >
            What We Offer
          </Typography>
          <TalentCard />
        </Box>

        {/* <Box py={5}>
          <WhyKlueCard />
        </Box> */}

        <Box sx={{ textAlign: "center" }} py={5}>
          <img src={bottamTalent} width="100%" />
        </Box>
      </Container>
    </Layout>
  );
};
export default TalentAcquisition;

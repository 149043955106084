import { Box, Typography, Grid, Divider } from '@mui/material';
import { FaAnglesRight } from "react-icons/fa6";
import rightArraowImg from "../../asset/images/rightArraw.png";
import React from 'react';

const TabCard = ({ data }) => {
  return (
    <Box p={1} sx={{borderRadius: "12px", height: "auto", justifyContent: "center" }}>
      <Typography variant='h4' mb={1} sx={{color:"#f49314", fontWaight:"700"}}>
        {data[0].heading}
      </Typography>
      <Divider style={{ backgroundColor: "#DD952B", height:"2px"}} />
      <br/>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={5}>
          {/* Replace with correct image source */}
          <img src={data[0].img} alt={data[0].heading} style={{ width: "100%",borderRadius:"12px" }} />
        </Grid>
        <Grid item xs={12} sm={8} md={7}>
          <Grid mt={1}>
          {['para1', 'para2', 'para3', 'para4', 'para5'].map((para, index) => (
            <Box key={index} sx={{ display: "flex", mb: 1}}>
              <img src={rightArraowImg} style={{height:"13px", marginTop:"9px"}} />
              <Typography sx={{ ml: 1,fontSize:"20px", color:"grey" }}>
                {data[0][para]}
              </Typography>
            </Box>
          ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TabCard;

import React, { useState } from "react";
import {
  Typography,
  Grid,
  Input,
  Card,
  Box,
  Button,
  FormLabel,
  FormGroup,
  TextField,
  styled,
  FormHelperText,
} from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import cvimg from "../asset/images/formCv.png";
import ModalConfirm from "../utills/ModalConfirm";

const aboutUs = [
  { value: "Hr", label: "Hr" },
  { value: "Finance", label: "Finance" },
  { value: "Technology", label: "Technology" },
  { value: "Sales Marketing", label: "Sales Marketing" },
  { value: "Other", label: "Other" },
];

const CvForm = () => {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    department: "Hr",
    upload_file: null,
    your_message: "",
  });

  const [showModal, setShowModal] = useState(false);
  const [uploadError, setUploadError] = useState(null);

  const handleInputChange = (name) => (e) => {
    let value = name === "upload_file" ? e.target.files[0] : e.target.value;
    setFormData({ ...formData, [name]: value });
  };

  const handleUpload = async () => {
    const data = new FormData();
    data.append("firstname", formData.firstname);
    data.append("lastname", formData.lastname);
    data.append("email", formData.email);
    data.append("phone", formData.phone);
    data.append("department", formData.department);
    data.append("upload_file", formData.upload_file);
    data.append("your_message", formData.your_message);

    try {
      const result = await fetch("https://klues.co.in/backend/api/resume/add", {
        method: "POST",
        body: data,
      });

      const resultData = await result.json();
      if (result.ok) {
        setFormData({
          firstname: "",
          lastname: "",
          email: "",
          phone: "",
          department: "Hr",
          upload_file: null,
          your_message: "",
        });
        setShowModal(true);
      } else {
        setUploadError(resultData.message || "An error occurred.");
      }
    } catch (error) {
      setUploadError("An unexpected error occurred.");
    }
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Grid container spacing={5}>
            <Grid item lg={12} md={12} sm={6} xs={12}>
              <img src={cvimg} alt="CV Form" style={{ maxWidth: "100%" }} />
            </Grid>
            <Grid item lg={12} md={12} sm={6} xs={12}>
              <Typography color="#686565" width="88%">
                Ready to unlock new career possibilities? Submit your CV today
                and let us connect you with exciting opportunities tailored to
                your expertise and aspirations. Your next career move starts
                here!
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Card
            sx={{
              border: "1px solid #DD952B",
              padding: "30px",
              borderRadius: "26px",
            }}
          >
            <Box
              sx={{
                display: { xs: "block", md: "flex", sm: "flex", lg: "flex" },
                justifyContent: "space-between",
              }}
              py={5}
            >
              <FormGroup sx={{ width: { sm: "80%", md: "40%" } }}>
                <FormLabel sx={{ color: "black" }}>First Name</FormLabel>
                <Input
                  color="secondary"
                  name="firstname"
                  onChange={handleInputChange("firstname")}
                  placeholder="e.g. James"
                  sx={{ borderBottom: "1px solid black" }}
                  value={formData.firstname}
                />
              </FormGroup>

              <FormGroup sx={{ width: { sm: "80%", md: "40%" } }}>
                <FormLabel sx={{ color: "black" }}>Last Name</FormLabel>
                <Input
                  color="secondary"
                  name="lastname"
                  onChange={handleInputChange("lastname")}
                  placeholder="e.g. Anderson"
                  sx={{ borderBottom: "1px solid black" }}
                  value={formData.lastname}
                />
              </FormGroup>
            </Box>

            <Box
              sx={{
                display: { xs: "block", md: "flex", sm: "flex", lg: "flex" },
                justifyContent: "space-between",
              }}
              pb={5}
            >
              <FormGroup sx={{ width: { sm: "80%", md: "40%" } }}>
                <FormLabel style={{ color: "black" }}>E-Mail</FormLabel>
                <Input
                  color="secondary"
                  name="email"
                  onChange={handleInputChange("email")}
                  placeholder="e.g. James@gmail.com"
                  sx={{ borderBottom: "1px solid black" }}
                  value={formData.email}
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                  required
                  error={
                    !!formData.email &&
                    !/^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/.test(
                      formData.email
                    )
                  }
                />
                <FormHelperText sx={{ color: "red" }}>
                  {formData.email &&
                  !/^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/.test(
                    formData.email
                  ) ? (
                      " * Invalid email format"
                  ) : (
                    ""
                  )}
                </FormHelperText>
              </FormGroup>
              <FormGroup sx={{ width: { sm: "80%", md: "40%" } }}>
                <FormLabel style={{ color: "black" }}>Phone</FormLabel>
                <Input
                  color="secondary"
                  name="phone"
                  onChange={handleInputChange("phone")}
                  placeholder="e.g. 087482445"
                  pattern="^\d{10}$"
                  required
                  error={!!formData.phone && !/^\d{10}$/.test(formData.phone)}
                  sx={{ borderBottom: "1px solid black" }}
                  value={formData.phone}
                />
                <FormHelperText sx={{ color: "red" }}>
                  {formData.phone && !/^\d{10}$/.test(formData.phone)
                    ? " * Invalid phone number format"
                    : ""}
                </FormHelperText>
              </FormGroup>
            </Box>

            <FormLabel style={{ color: "black" }}>Department</FormLabel>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { width: "60%" },
              }}
              noValidate
              autoComplete="off"
              pt={3}
            >
              <TextField
                select
                value={formData.department}
                name="department"
                onChange={handleInputChange("department")}
                SelectProps={{
                  native: true,
                }}
                variant="standard"
                color="secondary"
              >
                {aboutUs.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Box>
            <Box pt={5} pb={1}>
              <Button
                component="label"
                variant="outlined"
                color="secondary"
                size="large"
                startIcon={<FileUploadIcon />}
                sx={{
                  border: "1px solid #DD952B",
                  borderRadius: "10px",
                  fontWeight: "600",
                }}
              >
                Resume
                <VisuallyHiddenInput
                  type="file"
                  name="upload_file"
                  onChange={handleInputChange("upload_file")}
                />
              </Button>
            </Box>

            {uploadError && (
              <Typography color="error" pb={2}>
                {uploadError}
              </Typography>
            )}

            <Typography pb={2} fontSize={14} color="grey">
              Max File size 2.5 MB
            </Typography>

            <Typography pb={2} fontSize={16}>
              Or send your resume to
              <b style={{ color: "blue" }}> sales@klues.co.in</b>
            </Typography>

            <FormLabel sx={{ color: "black" }}>Your Message</FormLabel>

            <FormGroup>
              <Input
                color="secondary"
                name="your_message"
                onChange={handleInputChange("your_message")}
                placeholder="Tell us something about yourself"
                sx={{
                  borderBottom: "1px solid black",
                  margin: "15px 0px",
                }}
                value={formData.your_message}
              />
            </FormGroup>
            <Box py={3}>
              <Button
                sx={{
                  border: "1px solid #DD952B",
                  borderRadius: "10px",
                  fontWeight: "600",
                }}
                endIcon={<TrendingFlatIcon />}
                variant="outlined"
                color="secondary"
                size="large"
                onClick={handleUpload}
              >
                Submit
              </Button>
            </Box>
          </Card>
        </Grid>
      </Grid>

      <ModalConfirm
        text1={"Resume"}
        open={showModal}
        close={() => setShowModal(false)}
      />
    </div>
  );
};

export default CvForm;

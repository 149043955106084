import { Menu, MenuItem, Typography } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const DropDownMenu = ({
  menuData = [],
  status,
  handleCloseUserMenu,
  anchorElNav,
  handleOpenNavMenu,
}) => {
  return (
    <Menu
      sx={{ mt: "45px"}}
      id="menu-appbar"
      anchorEl={anchorElNav}
      anchorOrigin={{
        vertical: "top"
      }}
      keepMounted
      transformOrigin={{
        vertical: "top"
      }}
      open={Boolean(anchorElNav)}
      onClose={handleCloseUserMenu}
    >
      {menuData.map((item) => (
        <MenuItem key={item.title} onClick={handleCloseUserMenu}>
          <Link to={item.link} style={{textDecoration:"none", color:"black", fontSize:"15px"}}><Typography textAlign="center">{item.title}</Typography></Link>
        </MenuItem>
      ))}
    </Menu>
  );
};

export default DropDownMenu;

import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TabCard from './TabCard';
import { EducationEd, FinanceBanking, Manufacturing, Recruitment, Travel, WarehousingLogistics, automotive } from '../../data/industries/industriesData';
// Import other components similarly...

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={false}
      // hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      <Box sx={{ p: 3 }}>
          {children}
        </Box>
      {/* {value === index && (
        
      )} */}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const tabData = [
  { label: "Automotive", content: <TabCard  data={automotive}/> },
  { label: "Education & Ed-Tech", content: <TabCard  data={EducationEd}/> },
  { label: "Finance & Banking", content: <TabCard  data={FinanceBanking}/> },
  { label: "Manufacturing", content: <TabCard  data={Manufacturing}/> },
  { label: "Recruitment", content: <TabCard  data={Recruitment}/> },
  { label: "Travel", content: <TabCard  data={Travel}/> },
  { label: "Warehousing & Logistics", content: <TabCard  data={WarehousingLogistics}/> },
];

const JustifiedTabLabel = ({ text }) => {
  return (
    <div style={{ textAlign: "justify", width: '100%' }}>
      {text}
    </div>
  );
};

JustifiedTabLabel.propTypes = {
  text: PropTypes.string.isRequired,
};

export default function VerticalTabs() {
  const [value, setValue] = React.useState(0);
  const [selectedLabel, setSelectedLabel] = React.useState(tabData[0].label);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSelectedLabel(tabData[newValue].label);
  };

  return (
    <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', height: "auto", p: 3 }}>
      <Grid container>
        {/* <Grid item xs={12} sm={2} sx={{ border: "1px solid black", borderColor: 'divider', bgcolor: "#4672E3", color: "white", maxHeight: '400px', overflowY: 'auto', borderRadius: "12px", p: 2 }}>
          <Typography variant='h6' textAlign="center" gutterBottom sx={{ m: 0 }}>Industries</Typography>
          <hr color='white'/>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            scrollButtons="auto"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ color: "white", maxHeight: '320px', m: 0, '& .MuiTab-root': { minHeight: 0, py: 1 } }}
          >
            {tabData.map((tab, index) => (
              <Tab
                key={index}
                label={<JustifiedTabLabel text={tab.label} />}
                {...a11yProps(index)}
                sx={{
                  color: "white",
                  margin: "3px",
                  textAlign: "left",
                  width: "98%",
                  marginTop: '6px',
                  '&:hover': {
                    width: "98%",
                    borderRadius: "25px",
                    backgroundColor: 'white',
                    color: 'royalblue',
                  },
                  '&.Mui-selected': {
                    width: "98%",
                    borderRadius: "25px",
                    backgroundColor: 'white',
                    color: 'royalblue',
                  },
                }}
              />
            ))}
          </Tabs>
        </Grid> */}
        <Grid item xs={12} sm={12}>
          {tabData.map((tab, index) => (
            <TabPanel key={index} value={0} index={index}>
              {tab.content}
            </TabPanel>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
}

export const recruitmentIntro = {
  heading: "Recruitment Services",
  description:
    "Our Recruitment Service is an all-encompassing platform specifically designed to tackle the unique needs and challenges of modern-day recruitment. By harnessing the power of advanced technology, our platform provides an array of sophisticated tools and services that streamline operations, boost efficiency, and significantly enhance overall business performance. Partner with us and transform your recruitment process, making it more efficient and effective than ever before.",
};

export const hyperIntro = {
  heading: "Hyper Automation",
  description: "Klues deal in effective automation solutions which ensure your business processes are in order and enhance productivity. This is achieved through our outstanding services ranging frRevolutionize workflows, elevate customer satisfaction with seamless RPA integration, freeing your team for strategic endeavors.",
};

export const workforceIntro = {
  heading: "Workforce Management",
  description: "Klues Workforce Management are at the forefront of integrating innovative technologies with profound domain expertise to drive substantial business transformations. Our approach helps organizations build resilient, next-generation operating models that are adaptable and forward-thinking. We provide a broad spectrum of strategic solutions across multiple industries, enabling our clients to enhance their workforce capabilities, achieve positive outcomes, and sustain long-term growth.",
};

export const IntegratedIntro = {
  heading: "Integrated IT Solutions",
  description: "At Klues we offer complete IT solutions which are designed to make your operations simpler and more productive. Our end-to-end services include the entire range from system integration and network management to security protection and distributed networking services. Our specialty is developing effortless, scalable IT environments that adapt according to your changing business ",
};

export const efficientIntro = {
  heading: "Efficient Automation",
  description: "We deal in effective automation solutions which ensure your business processes are in order and enhance productivity. This is achieved through our outstanding services ranging from robotic process automation (RPA) to smart workflows as well as AI operations. We assist in the reduction of errors, time saving as well as improving the overall efficiency by doing repetitive tasks automatically and optimizing complicated workflows.",
};





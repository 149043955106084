import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import star from "../asset/images/home/star.png";

const ExcelCards = ({ data }) => {
  return (
    <>
      {data.map((skills, index) => (
        <Box key={index}>
          <Box pt={5}>
            <Typography color={"#DD952B"} fontSize={26} padding={2}>
              {skills.heading}
            </Typography>
            <Divider style={{ backgroundColor: "#DD952B" }} />
          </Box>

          <Grid container justifyContent="space-between" pt={5}>
            <Grid item lg={6} md={7} xs={12}>
              <Typography
                sx={{
                  color: "grey",
                  textAlign: "justify",
                  fontSize: { lg: 22, xs: 16 },
                }}
              >
                {skills.text1}
              </Typography>
            </Grid>
            <Grid item lg={5} md={5} xs={12}>
              <Box
                component="img"
                alt=""
                src={skills.img1}
                width="100%"
                sx={{
                  paddingLeft: { lg: 0, md: 3, xs: 0 },
                  pt: { md: 0, xs: 3 },
                  borderRadius:"35px"
                }}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent="space-between" pt={5}>
            <Grid lg={5} md={5} sm={12} xs={12}>
                  
                  <Box component="img" src={skills.img2} width="100%" sx={{borderRadius:"35px"}} />
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              {skills.points.map((point, pointIndex) => (
                <Box
                  sx={{ display: "flex", alignItems: "start" }}
                  key={pointIndex}
                  pt={2}
                >
                  <Box component="img" src={star} width={"50px"} px={1} />
                  <Typography
                    sx={{
                      color: "grey",
                      textAlign: "justify",
                      fontSize: { lg: 21, md: 19, xs: 18 },
                    }}
                  >
                    {point}
                  </Typography>
                </Box>
              ))}
            </Grid>
          </Grid>
        </Box>
      ))}
    </>
  );
};

export default ExcelCards;

import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import BrowseJobs from './pages/BrowseJobs';
import SubmityourCV from './pages/SubmityourCV';
import ReferyourFriend from './pages/ReferyourFriend';
import Pagenotfound from './pages/Pagenotfound';
import AboutUs from './pages/AboutUs';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import Merchandise from './pages/Merchandise';
import TalentAcquisition from './pages/services/TalentAcquisition';
import SkillBuilding from './pages/services/SkillBuilding';
import EfficientAutomation from './pages/services/EfficientAutomation';
import IntegratedSolutions from './pages/services/IntegratedSolutions';
import WorkforceManagement from './pages/services/WorkforceManagement';
import Industries from './pages/Industries';
// import Industries from './pages/Industries';


function App() {

  return (
   <>
   <Routes>
    <Route path="/efficient-automation" element={<EfficientAutomation/>} />
    <Route path="/integrated-solutions" element={<IntegratedSolutions/>} />
    <Route path="/workforce-management" element={<WorkforceManagement/>} />
    <Route path="/" element={<Home />} />
    <Route path="/Merchandise" element={<Merchandise/>} />
    <Route path="/industries" element={<Industries />} />
    <Route path="/recruitment-services" element={<TalentAcquisition/>} />
    <Route path="/training-services" element={<SkillBuilding/>} />
    <Route path="/browse-job" element={<BrowseJobs/>} />
    <Route path="/submit-cv" element={<SubmityourCV/>} />
    <Route path="/refer-friend" element={<ReferyourFriend/>} />
    <Route path="/about-us" element={<AboutUs/>} />
    <Route path="/terms-conditions" element={<Terms/>} />
    <Route path="/privacy-policy" element={<Privacy/>} />
    <Route path="*" element={<Pagenotfound/>} />
   </Routes>
   </>
  );
}

export default App;

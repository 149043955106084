import React from "react";
import { Box, Grid, Typography, Divider } from "@mui/material";
import star from "../asset/images/home/star.png";
import { talentService } from "../data/TalentServiceData";

const TalentCard = ({data}) => {
  const [cartprice, setCartPrice] = React.useState(1);
  return (
    <div>
    
      <Box>
        {talentService.map((data, index) => (
          <div key={index}>
            <Box pt={5}>
              <Typography color={"#DD952B"} fontSize={26} padding={2}>
                {data.heading1}
              </Typography>
              <Divider style={{ backgroundColor: "#DD952B" }} />
            </Box>

            <Grid
              container
              columnSpacing={10}
              rowSpacing={5}
              sx={{ marginTop: "20px" }}
            >
              <Grid item lg={6} sm={6} xs={12}>
                <Typography
                  color={"#787878"}
                  paddingBottom={"20px"}
                  sx={{
                    textAlign: "justify",
                    fontSize: { lg: 18, xs: 16 },
                  }}
                >
                  {data.subText1}{" "}
                </Typography>
                 {data.point1.map((point, index1) => (
                  <Box
                    sx={{ display: "flex" }}
                    key={index1}
                  >
                    <Box sx={{alignItems:"start",pr:1}}>
                    <img src={star}/>
                    </Box>
                    <Typography
                      sx={{
                        textAlign: "justify",
                        fontSize: { lg: 18, xs: 16 },
                      }}
                    >
                      {point}
                    </Typography>
                  </Box>
                ))}
              </Grid>
              <Grid item lg={6} sm={6} xs={12}>
                <Box>
                  <img src={data.img1} width={"100%"} />
                </Box>
              </Grid>
            </Grid>

            <Box pt={5}>
              <Typography
                padding={2}
                color={"#DD952B"}
                fontSize={26}
                textAlign={"right"}
              >
                {data.heading2}
              </Typography>
              <Divider style={{ backgroundColor: "#DD952B" }} />
            </Box>

            <Grid
              container
              columnSpacing={10}
              rowSpacing={5}
              sx={{ marginTop: "20px" }}
            >
              <Grid item lg={6} sm={6} xs={12}>
                <Box>
                  <img src={data.img2} width={"100%"} />
                </Box>
              </Grid>
              <Grid item lg={6} sm={6} xs={12}>
                <Typography
                  color={"#787878"}
                  paddingBottom={"20px"}
                  sx={{
                    textAlign: "justify",
                    fontSize: { lg: 18, xs: 16 },
                  }}
                >
                  {" "}
                  {data.subText2}{" "}
                </Typography>
                {data.point2.map((point, index2) => (
                  <Box
                    sx={{ display: "flex" }}
                    key={index2}
                  >
                    <Box sx={{alignItems:"start",pr:1}}>
                    <img src={star}/>
                    </Box>

                    <Typography
                      sx={{
                        textAlign: "justify",
                        fontSize: { lg: 18, xs: 16 },
                      }}
                    >
                      {point}
                    </Typography>
                  </Box>
                ))}
              </Grid>
            </Grid>
          </div>
        ))}
      </Box>
    </div>
  );
};

export default TalentCard;

import React from "react";
import VerticalTabs from "../../component/Industries/Verticals";
import Layout from "../../component/Layout";
import { Box } from "@mui/material";

const Industries = () => {
  return (
    <Layout>
      <Box mt={16} sx={{ height: "auto" }}>
        <VerticalTabs />
      </Box>
    </Layout>
  );
};

export default Industries;

import b1 from "../asset/images/subServices/b1.png";
import b2 from "../asset/images/subServices/b2.png";
import b3 from "../asset/images/subServices/b3.png";
import b4 from "../asset/images/subServices/b4.png";
import b5 from "../asset/images/subServices/b5.png";
import IT1 from "../asset/images/integratedImg/IT1.jpg";
import IT2  from "../asset/images/integratedImg/IT2.jpg";
import IT3 from "../asset/images/integratedImg/IT3.png";
import IT4 from "../asset/images/integratedImg/IT4.png";
import E1 from "../asset/images/efficientImg/E1.png";
import E2 from "../asset/images/efficientImg/E2.png";


export const workforce = [
  {
    id: 1,
    img1: b1,
    heading: "Workforce Augmentation",
    text1:
      "Are you looking to reduce costs, increase productivity, automate processes, and boost team morale? Many organizations are on the hunt for this elusive solution and have found success by embracing Workforce Management (WFM). Workforce Management at Klues's offers a suite of solutions designed to streamline and automate resource allocation, improve efficiency, forecast workloads, and manage schedules.",
    text2:
      "In today’s fast-changing market, with more regulations and a varied, dispersed workforce, effective workforce management is more critical and complex than ever. Integrated WFM technology is essential for gaining strategic insights, making necessary adjustments, and aligning HR with business goals. Without it, inefficiencies and missed opportunities can hurt the bottom line.",

    points: [
      "Leveraging the latest technologies to streamline processes and improve operational efficiency.",
      "Utilizing cost-effective tools and strategies to maximize return on investment.",
      "Building high-performing workforce that consistently delivers exceptional value",
      "Managing every stage of the employee journey, from recruitment to retirement, ensuring sustained success.",
    ],
  },
  {
    id: 2,
    img1: b2,
    heading: "Digital Consulting",
    text1:
      "The wave of digital transformation is leading organizations to ponder significant questions: How can we reimagine our business, reshape our culture, introduce new work practices, create innovative products and services, and better connect with employees, customers, partners, and suppliers?",
    // text3:"Digital transformation presents significant challenges for organizations, where change can quickly become overwhelming. ",
    text2:
      "Klues's Digital Consulting focuses on the essential factors: insights, interactions, integrations, and innovations. We enable brands, businesses, and their customers to achieve remarkable outcomes, With Klues's Digital Consulting, clients can navigate their digital transformation journeys with confidence, achieving faster time-to-market and reduced costs while delivering exceptional customer experiences",
    points: [
      "Leverage data-driven insights to shape and direct digital transformation strategies.",
      "Create engaging, multi-channel customer experiences with seamless, platform-independent solutions.",
      "Integrate new technologies smoothly with existing systems to support business objectives",
      "Implement cutting-edge technologies and methodologies to stay ahead of industry trends",
    ],
  },
  {
    id: 3,
    img1: b3,
    heading: "Business & Process Transformations​",
    text1:
      "Business & Process Transformation is a collaborative initiative aimed at changing the financial, operational, and strategic direction of your business to achieve transformative results.",
    text2:
      "Do you envision a better future for your business but face obstacles due to outdated systems and complex processes? Klues's Business Transformation services are here to assist. We provide consultation, design, implementation, and management of industry-specific solutions to revamp core operations, improve efficiency, and optimize assets. ",
    points: [
      "Streamline processes to boost productivity and reduce costs.",
      "Implement modern technologies to enhance business capabilities.",
      "Tailor sector-specific strategies to meet unique business needs.",
      "Identify and capture new avenues for business expansion.",
    ],
  },
  {
    id: 4,
    img1: b4,
    heading: "Engineering Solution",
    text1:
      "In today's fast-paced and ever-changing business environment, enterprises are constantly striving to stay ahead of the curve. With the rapid advancement of technology and digital transformation, businesses are increasingly turning to digitally powered engineering solutions to meet their evolving needs.",
    // text3:"Klues's recognizes this demand and offers comprehensive Engineering Solutions that are specifically designed for the current era. ",
    text2:
      "With Klues's Engineering Solutions, enterprises can confidently navigate the complexities of the modern business landscape. By leveraging our innovative approaches and advanced technologies, businesses can not only stay ahead of the competition but also thrive in the 'Now' era. Whether it's optimizing operations, enhancing efficiency, or developing groundbreaking products, we are committed to empowering enterprises to achieve their goals and stay ahead of the curve.",
    points: [
      "We leverage the latest technologies to engineer solutions that meet the demands of the modern world..",
      "From conceptualization to execution, our engineering solutions cover the entire product lifecycle, ensuring continuity and quality.",
      "Our team combines deep technological expertise with a solution-oriented mindset to deliver impactful results for your business.",
      "We provide ongoing support and enhancements to ensure that our solutions continue to add value over time.",
    ],
  },
  {
    id: 5,
    img1: b5,
    heading: "Data & Customer Analytics",
    text1:
      "Data is the fundamental element of business, powered by technology, enhanced by AI, and harnessed by people. Klues's stands at the crossroads of art and science, intelligence and creativity, linking data with people. We see the possibilities data offers and use our experience to turn them into reality.",
    text2:
      "To propel growth, innovate, and boost operational efficiency, organizations must fully leverage their data assets. Adopting a data-driven culture is now vital. Klues's Analytics leads this transformation, focusing on business analytics in various aspects of your organization, including processes, functions, and lines of business.",
    // text3:"By anchoring our approach in analytics and AI, we merge domain expertise with advanced technologies, delivering insights that transcend traditional boundaries, prevent fraud and mitigate risks, ensuring business security.",
    points: [
      "Improve workforce productivity with data-driven process automation and operational enhancements.",
      "Unlock data value with strategic analytics and creative monetization methods",
      "Manage expenses effectively with analytics-based insights and resource optimization.",
      "Redefine customer experiences using data-driven insights, customized interactions, and seamless service.",
    ],
  },
];

export const intergrateIT = [
  {
    id: 1,
    img1: IT1,
    heading: "SaaS Products",
    text1:
      "We offer the contemporary solutions for Software as a Service (SaaS) that enables various businesses to work effectively and efficiently, making operations and processes easier. We provide SaaS solutions that give fast access to productive programs and applications, so no complicated installations are required, and there is no need in extensive hardware.",
    text2:
      "Revitalize your application portfolio by offering comprehensive support across the entire application lifecycle through our holistic Application Services. This encompasses application strategy, development, modernization, integration, testing, and management, ensuring a seamless journey from inception to maintenance",

    points: [
      "Our SaaS solutions expand in line with your business and this includes scalable resources and flexible pricing models that adapt to your changeable needs without having to bother with hardware or infrastructure management.",
      "Our SaaS offerings enable teams to collaborate from anywhere seamlessly as they get the latest software updates & data in real time hence increasing operational efficiency and productivity at company level.",
      "We are always staying ahead of the curve in the SaaS industry where we make sure your business stays relevant by keeping up with current market trends through modern technology",
      "Don't worry about the safety and industry regulation compliant of your data. Offering peace of mind to you and your customers, our SaaS solutions contain strong security tools, regular updates, and a strict adherence to compliance standards.",
    ],
  },
  {
    id: 2,
    img1: IT2,
    heading: "App Dev & Management",
    text1:
      "We specialize in comprehensive app development and management services tailored to bring your vision to life. Our expert team of developers crafts custom mobile and web applications that are intuitive, user-friendly, and scalable.",
    text2:
      "Ensuring that your app performs well and scales with your business from the time it is conceived and designed to the point where it is deployed and continues to be maintained is our commitment. ",
    points: [
      "Continuously improve your application's functionality and user experience with seamless upgrades and enhancements tailored to meet evolving business needs.",
      "Ensure smooth operation and reliability by promptly addressing bugs and technical issues, minimizing disruptions and maximizing user satisfaction.",
      "Empower users with comprehensive support and training programs to optimize their experience and proficiency, fostering adoption and efficiency.",
      "Enhance application efficiency and responsiveness through meticulous performance optimization techniques, ensuring optimal user experience and operational effectiveness.",
    ],
  },
  {
    id: 3,
    img1: IT3,
    heading: "Software Testing Services",
    text1:
      "Our Software Testing Services are powerful and engineered to guarantee quality, dependability and efficiency of your applications. We also provide all-inclusive testing services which include functional, performance and security testing among others.",
    text2:
      "Our software testing services involve a capable team and advanced tools for early issue identification and resolution, so that you can launch your software without worrying about bugs.",
    points: [
      "Ensure that your software functions as intended with our functional testing services. We rigorously test each feature and functionality to verify that they meet specified requirements and deliver the expected results, minimizing the risk of defects in production",
      "Our testing solutions help to find out what might be wrong in your software when it gets loaded, stressed or getting better each time which entails bottlenecks analysis and performance engineering to get maximum output.",
      "We leverage the latest tools, technologies, and automation frameworks to optimize the testing process, enhance efficiency, and reduce time-to-market, saving you valuable time and resources.",
      "Ensure a uninterrupted user experience with our usability testing services. We evaluate the user interface, navigation, and overall usability of your software to identify areas for improvement and enhance user satisfaction, usability, and accessibility.",
    ],
  },
  {
    id: 4,
    img1: IT4,
    heading: "IoT & IIoT",
    text1:
      "The digital revolution is being driven by innovative technologies such as the Internet of Things (IoT) and the Industrial Internet of Things (IIoT). These technologies enable businesses to connect and optimize various devices and equipment through. the Internet, leading to enhanced operational efficiency, informed decision-making, and new revenue streams.",
    text2:
      "IIoT is a specialized branch of IoT that specifically applies to industrial environments, particularly in manufacturing facilities. It plays a pivotal role in Industry 4.0, which marks the next phase of the industrial revolution.",
    points: [
      "IoT devices gather data to help improve resource usage and productivity, reducing costs and enhancing performance.",
      "Predictive maintenance reduces downtime, prevents severe damage, and ensures reliable access to resources.",
      "Remote equipment control allows for flexibility in operation and adaptation to changing conditions.",
      "Connect infrastructure to the Internet to increase security and protect against unauthorized access and data loss.",
    //   "Smart sensors and big data analytics enable more informed decision-making and better preparation for future changes.",
    //   "IoT and IIoT can reduce costs, improve decision-making, and open up new business opportunities to enhance ROI.",
    ],
  },
];

export const efficient = [
    {
      id: 1,
      img1: E1,
      heading: "RPA & Hyper Automation",
      text1:
        "Here at Klues's, we specialize in Robotic Process Automation (RPA) and Hyper automation solutions that transform how companies work. Our services based on RPA, automate repetitive, rule-based tasks allowing your employees to pay more attention to higher-value activities.",
      text2:
        "Our Hyperautomation solutions go beyond traditional automation by fusing AI, ML, and advanced analytics, making it intelligent and end to end. Advanced effectiveness, better precision, and more flexibility are guaranteed by this comprehensive method.",
  
      points: [
        "RPA excels at executing mundane, repetitive tasks swiftly and tirelessly, enabling your workforce to focus on higher-value activities. With RPA in place, you can achieve significant productivity gains as routine tasks are completed faster and with fewer errors, leading to enhanced operational efficiency.",
        "With RPA handling repetitive tasks, your employees can redirect their energy towards meaningful endeavors such as process enhancement, innovation, and personalized customer interactions. This shift not only boosts morale but also cultivates a culture of continuous improvement and professional growth.",
        "Our RPA solutions optimizes data collection from self-service portals, simplifying processes for both agents and customers. By automating data input tasks, we reduce the effort required from customers and enable agents to deliver faster, more accurate service, ultimately enhancing the overall customer experience.",
        "Our group consists of experienced Lean Six Sigma professionals and expert documentation analysts whose core responsibility is evaluating service contexts to expose and effect process improvement chances.",
      ],
    },
    {
      id: 2,
      img1: E2,
      heading: "AI & Gen AI",
      text1:
        "At Klues's, we are experts in AI (Artificial Intelligence) and Generative AI (Artificial Intelligence). Our artificial intelligence services are based on algorithms from machine learning, as well as deep learning which are used to analyze data, find out more things about it and to automate decision making.",
      text2:
        "Moreover, creation of new content, designs, and solutions is supported through generative AI which enables it to learn from patterns and data that exist today Responsive artificial intelligence can be used to improve consumer experience, optimize operations. Different institutions have been transformed by these advanced technologies, providing them with unprecedented growth and innovation prospects.",
      points: [
        "Our focus is to comprehend the particular needs of your establishment, hence we create Generative AI solutions that best suit your goals and demands.",
        "Reckon on us for sound backing every step of the course. We are dedicated to giving you the direction and specialization demanded to achieve success through our Generative AI services from the introductory consultation all through.",
        "To ensure cutting-edge solutions with proven results, we employ highly skilled AI professionals who are passionate about innovation.",
      ],
    },
  ];

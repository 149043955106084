import img1 from "../asset/images/browse/tailored.png"
import img2 from "../asset/images/browse/innovation.png"
import img3 from "../asset/images/browse/transparent.png"
import img4 from "../asset/images/browse/Extansive.png"


export const jobcardData=[
    {
        image:img1,
        head:"Tailored Solution",
        text:"Our personalized approach is designed to meet your unique requirements, offering tailored solutions that deliver impactful outcomes through strategic planning and execution."
    },
    {
        image:img2,
        head:"Innovation",
        text:"We continuously lead the pack by offering innovative solutions and incorporating the latest technologies to produce successful outcomes in a fast-paced market"
    },
    {
        image:img3,
        head:"Transparent Communication",
        text:"Anticipate transparent and open communication at every stage, fostering trustworthiness and accountability "
    },
    {
        image:img4,
        head:"Extensive Networks",
        text:"Anticipate transparent and open communication at every stage, fostering trustworthiness and accountability."
    }

];

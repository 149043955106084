import Laptop1 from '../asset/images/Merchandise/LaptopBag/bag1.png'
import Laptop2 from '../asset/images/Merchandise/LaptopBag/bag2.png'
import Laptop3 from '../asset/images/Merchandise/LaptopBag/bag3.png'

import Diaries1 from '../asset/images/Merchandise/Diaries/dairy 1.png'
import Diaries2 from '../asset/images/Merchandise/Diaries/dairy 2.png'
import Diaries3 from '../asset/images/Merchandise/Diaries/dairy 3.png'

import Wallet1 from '../asset/images/Merchandise/Wallet/wallet 1.png'
import Wallet2 from '../asset/images/Merchandise/Wallet/wallet 2.png'
import Wallet3 from '../asset/images/Merchandise/Wallet/wallet 3.png'

import flask1 from '../asset/images/Merchandise/Flask/flask1.png'
import flask2 from '../asset/images/Merchandise/Flask/flask2.png'
import flask3 from '../asset/images/Merchandise/Flask/flask3.png'

import Pen1 from '../asset/images/Merchandise/Pen/pen 1.png'
import Pen2 from '../asset/images/Merchandise/Pen/pen 2.png'
import Pen3 from '../asset/images/Merchandise/Pen/pen 4.png'

import kit1 from '../asset/images/Merchandise/WelcomeKit/kit1.png'
import kit2 from '../asset/images/Merchandise/WelcomeKit/kit2.png'
import kit3 from '../asset/images/Merchandise/WelcomeKit/kit3.png'
import kit4 from '../asset/images/Merchandise/WelcomeKit/kit4.png'

export const OfficeEssentialsData = [
  {
    id: 1,
    head: "laptop Bag",
    text: "Stylish and functional bags to protect your devices on the go. ",
    image: [
      { id: 1, image: Laptop1 },
      { id: 2, image: Laptop2 },
      { id: 3, image: Laptop3 },
    ],
  },
  {
    id: 2,
    head: "Diaries",
    text: "Keep track of important meetings, deadlines, and ideas with our premium diaries.",
    image: [
      { id: 1, image: Diaries1 },
      { id: 2, image: Diaries2 },
      { id: 3, image: Diaries3 },
    ],
  },
  {
    id: 3,
    head: "Wallets",
    text: "Sleek and durable wallets to keep your essentials organized.",
    image: [
      { id: 1, image: Wallet1 },
      { id: 2, image: Wallet2 },
      { id: 3, image: Wallet3 },
    ],
  },
  {
    id: 4,
    head: "Flask",
    text: "Stay hydrated throughout the day with our premium-quality flasks.",
    image: [
      { id: 1, image: flask1 },
      { id: 2, image: flask2 },
      { id: 3, image: flask3 },
    ],
  },
  {
    id: 5,
    head: "Pens",
    text: "Make a statement with our elegant and ergonomic pens.",
    image: [
      { id: 1, image: Pen1 },
      { id: 2, image: Pen2 },
      { id: 3, image: Pen3 },
    ],
  },
];
export const ProductHigh = [
  {
    id: 1,
    head: "Welcome Kit",
    text: "Stylish and functional bags to protect your devices on the go. ",
    image: [
      { id: 1, image: kit1 },
      { id: 2, image: kit2 },
      { id: 3, image: kit3 },
      { id: 4, image: kit4 },
    ],
  },
];

import React from "react";
import ServiceCard2 from "../../component/ServiceCard2";
import Layout from "../../component/Layout";
import { Box, Container } from "@mui/material";
import SubHeader from "../../component/SubHeader";
import ServiceIntro from "../../component/ServiceIntro";
import { workforceIntro } from "../../data/serviceHead";
import { workforceTop } from "../../data/SubHeaderData";
import { workforce } from "../../data/subServiceData";
import  bottamWorkforce from "../../asset/images/bottamBnrImgs/bottamWorkforce.png";

const WorkforceManagement = () => {
  return (
    <Layout>
      <Container
        sx={{ maxWidth: { lg: "lg", xl: "xl", md: "md", sm: "sm", xs: "xs" } }}
      >
        <Box sx={{ mt: 15 }}>
          <SubHeader data={workforceTop} />
        </Box>

        <Box>
          <ServiceIntro data={workforceIntro} />
        </Box>
        <Box pb={5}>
          <ServiceCard2 data={workforce} />
        </Box>

        <Box sx={{ textAlign: "center" }} py={5}>
          <img src={bottamWorkforce} width="100%" style={{borderRadius:"35px"}} />
        </Box>
      </Container>
    </Layout>
  );
};

export default WorkforceManagement;

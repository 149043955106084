import React from "react";
import Layout from "../component/Layout";
import { Box, Container } from "@mui/material";
import SubHeader from "../component/SubHeader";
import { Unleashing } from "../data/SubHeaderData";
import AboutCard from "../component/AboutCard";
import ValueCard from "../component/ValueCard";
import WhyKlueCard from "../component/WhyKlueCard";
import { aboutusValue } from "../data/WhyKluesData";


const AboutUs = () => {
  return (
    <div>
      <Layout>
        <Container
          sx={{
            maxWidth: { lg: "lg", xl: "xl", md: "md", sm: "sm", xs: "xs" },
          }}
        >
          {/* <Box sx={{ mt:15}}>
            <SubHeader data={Unleashing} />
          </Box> */}

          <Box py={5} sx={{ mt:15}}>
            <ValueCard />
          </Box>

          <Box py={5}>
            <AboutCard/>
          </Box>
          <Box py={5}>
          <WhyKlueCard data={aboutusValue}/>
        </Box>
        </Container>
      </Layout>
    </div>
  );
};

export default AboutUs;

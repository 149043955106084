import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Box, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import "../App.css";
import bgimg from "../asset/images/home/bgcardimg.png";
import star from "../asset/images/home/star.png";
import { cardData } from "../data/KluesPerksData";


const styles = {
  card: {
    backgroundSize: "cover",
    color: "black",
    textAlign: "center",
    minHeight: "400px",
    position: "relative",
    cursor: "pointer",
  },
  default_content: {
    position: "absolute",
    top: "35%",
    left: 0,
    padding: "20px",
    textAlign: "start",
    transition: "all 300ms ease",
  },
  changed_content: {
    position: "absolute",
    padding: "20px",
    textAlign: "start",
    top: "0%",
    animation: "bounce 0.6s ease",
  },
  cardNum1: {
    fontSize: 60,
    fontWeight: 450,
    color: "#4672E3",
    transition: "all 300ms ease",
  },
  cardNum2: {
    fontSize: 96,
    fontWeight: 450,
    transition: "all 300ms ease",
  },
};

const KluesPerks = () => {
  const [selectedCard, setSelectedCard] = useState(true);
  const handleCardClick = (card) => {
    setSelectedCard((prevCard) => (prevCard === card ? null : card));
  };
  return (
    <>
      <style>
        {`
          @keyframes bounce {
            40%, 80% {
              transform: translateY(0);
            }
            35% {
              transform: translateY(-40px);
            }
          }
        `}
      </style>
      <Box>
        <Typography
          sx={{
            fontSize: { lg: 40, md: 32, xs: 20 },
            fontWeight: "bold",
            pb: 1,
          }}
        >
          The Klues Perks
        </Typography>
        <Typography sx={{ fontSize: { md: 16, xs: 14 }, pb: 3 }} color="grey">
          Uncover the Narrative of Our Endeavor, Fostering Career Advancement
          and Collaborative Success.
        </Typography>
      </Box>

      <Grid container spacing={4}>
        {cardData.map((card) => (
          <Grid item key={card.id} xs={12} md={6} lg={4}>
            <Card
              style={{
                ...styles.card,
                backgroundImage: `url(${bgimg})`,
                borderRadius:"20px",
              }}
              onClick={() => handleCardClick(card)}
            >
              {selectedCard && selectedCard.id === card.id ? (
                <CardContent style={styles.changed_content}>
                  <Typography style={styles.cardNum1}>{card.Number}</Typography>
                  <Typography fontSize={30} fontWeight={450} color={"#4672E3"}>
                    {card.text}
                  </Typography>
                  <List fontSize={5}>
                    {card.content1.map((item1) => (
                      <>
                        <ListItem
                          alignItems="flex-start"
                          sx={{ paddingLeft: 0 }}
                        >
                          <ListItemIcon sx={{ minWidth: "30px" }}>
                            <img src={star} alt="" />
                          </ListItemIcon>
                          <ListItemText>{item1}</ListItemText>
                        </ListItem>
                      </>
                    ))}
                  </List>
                </CardContent>
              ) : (
                <CardContent style={styles.default_content}>
                  <Typography style={styles.cardNum2}>{card.Number}</Typography>
                  <Typography fontSize={30} fontWeight={450}>
                    {card.text}
                  </Typography>
                </CardContent>
              )}
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default KluesPerks;

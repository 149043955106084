import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import React from "react";
import star from "../asset/images//home/star.png";
import { valueData } from "../data/KluesPerksData";

const ValueCard = () => {
  return (
    <div>
      <Box>
        <Typography
          sx={{
            fontSize: { lg: 40, md: 32, xs: 20 },
            fontWeight: "bold",
            pb: 1,
          }}
        >
          Welcome to Klues Service
        </Typography>
        <Typography sx={{ fontSize: { md: 16, xs: 14 }, pb: 3 }} color="grey">
          At Klues Service, we are dedicated to transforming businesses through
          our comprehensive suite of Workforce Management, Integrated IT
          Solutions, Efficient Automation, and Training services. Our commitment
          to excellence and innovation drives us to deliver exceptional results,
          tailored to meet the unique needs of our clients.
        </Typography>
      </Box>

      
    </div>
  );
};

export default ValueCard;

import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import React from "react";
import img1 from "../asset/images/aim.png";
import img2 from "../asset/images/mission.png";
import img3 from "../asset/images/vision.png";
import star from "../asset/images/home/star.png";
import { valueData } from "../data/KluesPerksData";

const AboutCard = () => {
  return (
    <div>
      <Box py={5}>
        <Grid container justifyContent="space-between" pt={2} pb={3}>
          <Grid item lg={6} md={7} xs={12}>
            <Typography
              sx={{
                color: "black",
                fontSize: { lg: 40, md: 32, xs: 20 },
                fontWeight: "bold",
              }}
              py={3}
            >
              Our Mission
            </Typography>
            <Box sx={{ display: "flex", alignItems: "start" }}>
              <Box component="img" src={star} width={"50px"} />
              <Typography
                sx={{
                  color: "grey",
                  textAlign: "justify",
                  fontSize: { lg: 22, md: 20, xs: 18 },
                }}
              >
                At Klues Service, our mission is to provide innovative,
                reliable, and efficient solutions that empower our clients to
                achieve their business goals. We strive to be a trusted partner,
                delivering unparalleled service and value through our expertise
                and commitment to excellence.
              </Typography>
            </Box>
          </Grid>

          <Grid item lg={5} md={5} xs={12}>
            <Box
              component="img"
              alt=""
              src={img2}
              width="100%"
              sx={{
                paddingLeft: { lg: 0, md: 3, xs: 0 },
                pt: { md: 0, xs: 3 },
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box py={5}>
        <Grid container justifyContent="space-between" pt={2}>
          <Grid item lg={5} md={5} xs={12}>
            <Box
              component="img"
              alt=""
              src={img3}
              width="100%"
              sx={{
                paddingLeft: { lg: 0, md: 3, xs: 0 },
                pt: { md: 0, xs: 3 },
              }}
            />
          </Grid>
          <Grid item lg={6} md={7} xs={12}>
            <Typography
              sx={{
                color: "black",
                fontSize: { lg: 40, md: 32, xs: 20 },
                fontWeight: "bold",
              }}
              py={3}
            >
              Our Vision
            </Typography>
            <Box sx={{ display: "flex", alignItems: "start" }}>
              <Box component="img" src={star} width={"50px"} p={1} />
              <Typography
                sx={{
                  color: "grey",
                  textAlign: "justify",
                  fontSize: { lg: 22, md: 20, xs: 18 },
                }}
              >
                Our vision is to be the global leader in workforce management
                and integrated IT solutions, recognized for our innovation,
                excellence, and ability to drive business transformation. We aim
                to build a future where businesses of all sizes can leverage
                cutting-edge technology and automation to achieve unprecedented
                levels of efficiency and success.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box py={5}>
        <Grid container justifyContent="space-between" pt={2} pb={3}>
          <Grid item lg={6} md={7} xs={12}>
            <Typography
              sx={{
                color: "black",
                fontSize: { lg: 40, md: 32, xs: 20 },
                fontWeight: "bold",
              }}
              py={3}
            >
              Our Journey
            </Typography>
            <Box sx={{ display: "flex", alignItems: "start" }}>
              <Box component="img" src={star} width={"50px"} />
              <Typography
                sx={{
                  color: "grey",
                  textAlign: "justify",
                  fontSize: { lg: 22, md: 20, xs: 18 },
                }}
              >
                Klues Service began with a vision to provide top-notch software,
                RPA, and staff augmentation services to clients in India and
                abroad. Starting with a few dedicated clients, we have grown
                exponentially thanks to our unwavering commitment to quality and
                customer satisfaction. Today, we boast a talented team
                comprising over 155 professionals in RPA, Software Development,
                more than 50 experts in Staff Augmentation, and 5+ support
                staff, all working cohesively to deliver exceptional service.
              </Typography>
            </Box>
          </Grid>

          <Grid item lg={5} md={5} xs={12}>
            <Box
              component="img"
              alt=""
              src={img1}
              width="100%"
              sx={{
                paddingLeft: { lg: 0, md: 3, xs: 0 },
                pt: { md: 0, xs: 3 },
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Typography
        sx={{
          fontSize: { lg: 32, md: 32, xs: 20 },
          fontWeight: "bold",
          pb: 1,
        }}
      >
        Why Choose Klues Service?
      </Typography>
      <Grid container spacing={4}>
        {valueData.map((data, index) => (
          <Grid item lg={4} md={6} xs={12} key={index}>
            <Card component="Paper" elevation={0}>
              <CardContent sx={{ textAlign: "justify", px: 2 }}>
                <Box component="img" src={data.img} height="90px" />
                <Typography
                  color="primary.blue"
                  sx={{
                    fontSize: { lg: 30, md: 26, xs: 22 },
                    fontWeight: "500",
                  }}
                >
                  {data.title}
                </Typography>
                <Box display="flex" alignContent="flex-start">
                  <Box alignItems="start" pr={1}>
                    <img src={star} width="30px" />
                  </Box>

                  <Typography
                    component="p"
                    sx={{ fontSize: { lg: 20, md: 18, xs: 16 } }}
                  >
                    {data.des}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default AboutCard;

 import industries from '../../asset/images/Industries/industries.jpg';
 import industries2 from '../../asset/images/Industries/industries2.png';
 import industries3 from '../../asset/images/Industries/industries3.png';
 import industries4 from '../../asset/images/Industries/industries4.jfif';
 import industries5 from '../../asset/images/Industries/industries5.jfif';
 import travel from '../../asset/images/Industries/travel.jpg';
 import warehouse from '../../asset/images/Industries/warehouse.png';
 export const automotive=[{
    img:industries3,
    heading:"Automotive",
    para1:" Klues is transforming data annotation for autonomous vehicles using cutting-edge AI tools and a flexible team.",
    para2:" Our advanced models ensure privacy by automatically obscuring personal information in images.",
    para3:"We conduct rigorous analysis and employ sophisticated technology for superior data annotation.",
    para4:"Stringent security measures are prioritized throughout the data handling process.",
    para5:"We ensure utmost confidentiality and reliability for clients in the dynamic field of self-driving technology."
 }]

 export const EducationEd=[{
    img:industries2,
    heading:"Education & Ed-Tech",
    para1:" Klues designs cutting-edge technology for accessible, affordable, and engaging education.",
    para2:"Our digital platforms deliver seamless education through a blend of physical and digital methods.",
    para3:"Hyper-personalized learning is enabled through our digital university and extended reality features.",
    para4:"Metaverse integration provides students with an immersive and self-directed learning environment.",
    para5:"We are transforming the Ed-Tech landscape with innovative educational technology solutions."
 }]


 export const FinanceBanking=[{
    img:industries,
    heading:"Finance & Banking",
    para1:" Klues offers a data-driven, automated suite to revolutionize customer interactions across key areas.",
    para2:"Access real-time data to ensure quality and compliance.",
    para3:"Enhance defenses against financial crime with our suite of solutions.",
    para4:"Utilize our machine learning-based framework to monitor, evaluate, and manage counterparties' risks in real time",
    para5:"Our automation-led approach integrates various service channels into a unified pipeline for improved customer experience management."
 }]

 export const Manufacturing=[{
    img:industries4,
    heading:"Manufacturing",
    para1:" Klues provides end-to-end engineering services to accelerate the manufacturing process.",
    para2:"Deploy our machine learning models to enable predictive maintenance seamlessly.",
    para3:"Our digital solutions empower manufacturers with capabilities for outcome-based performance contracts.",
    para4:"Achieve exponential service revenue growth, higher profit margins, and exceptional customer experiences with our solutions.",
    para5:"Enhance operational efficiency through digitalization, real-time visibility, and machine-based supervision for a responsive manufacturing enterprise."
 }]

 export const Recruitment=[{
    img:industries5,
    heading:"Recruitment",
    para1:" Klues' AI solutions enhance hiring decisions by aligning them with specific organizational needs for a more talented candidate pool.",
    para2:"Automate and streamline recruitment tasks with our solutions, leading to improved efficiency and cost savings.",
    para3:"Utilize our AI Resume Screening Solutions for better and faster recruitment choices.",
    para4:"Ensure seamless communication within your automated tools to improve coordination and decision-making.",
    para5:"Monitor, organize, and track job applications efficiently with our advanced recruitment tools."
 }]



 
 export const Travel=[{
    img:travel,
    heading:"Travel",
    para1:"Klues leverage deep industry expertise to help online travel marketplaces optimize platforms and enhance search functionality.",
    para2:"Our technology personalizes recommendations, ensuring users effortlessly find perfect travel options.",
    para3:"We facilitate smooth online bookings and custom apps, offering highly personalized services to meet future guest demands.",
    para4:"Utilize generative AI, advanced analytics, and personalized automation to engage guests more meaningfully and efficiently.",
    para5:"Seamlessly scale your operations with our omnichannel integration to meet fluctuating demand."
 }]

 export const WarehousingLogistics=[{
    img:warehouse,
    heading:"Warehousing & Logistics",
    para1:"Klues provides customized solutions that merge advanced technology with excellent service to optimize logistics.",
    para2:"Boost the movement of goods across connected logistics networks with our warehouse management, featuring location optimization and order processing.",
    para3:"Our IoT-enabled track and trace system delivers scalable, automated inventory management within facilities.",
    para4:"Increase operational efficiency by allowing operators to swiftly locate and manage assets.",
    para5:"Minimize manual counting and eliminate errors, ensuring higher accuracy and control in inventory management."
 }]


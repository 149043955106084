import React, { useState } from "react";
import { Box, Grid, Tabs, Tab, Typography, Paper } from "@mui/material";
import { KluesBringData } from "../data/smTabsData";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const KlusBring = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {KluesBringData.map((data, index) => (
        <Grid  rid container key={index} spacing={2} mt={3}>
          <Grid item lg={6} md={6} xs={12}>
            <img src={data.img} width="100%" />
          </Grid>
          <Grid item lg={6} md={6} xs={12} >
            <Box sx={{mt:{xl:5,lg:0}}}>
              <Tabs
                value={value} 
                onChange={handleChange}
                aria-label="basic tabs example"
                TabIndicatorProps={{ style: { height: 0 } }}
                allowScrollButtonsMobile="true"
                style={{ justifyContent: "space-between" }}
                variant="scrollable"
                scrollButtons="none"
                indicatorColor="none"
              >
                {data.tabs.map((tab, tabindex) => (
                  <Tab
                    label={tab}
                    sx={{
                      borderRadius: 10,
                      border: "1px solid #4672E3",
                      backgroundColor: value === tabindex ? "#4672E3" : "white",
                      color: value == tabindex ? "#ffff !important" : "#4672E3",
                      marginRight:5,
                      fontWeight: 600,
                    }}

                  />
                ))}
              </Tabs>
            </Box>
            {data.tabContent.map((contt, cttindex) => (
              <CustomTabPanel value={value} index={cttindex}>
                <Typography
                  sx={{
                    fontWeight: "600",
                    color: "#4672E3",
                    fontSize: { lg: 30, md: 25, sm: 25, xs: 20 },
                    py:{xl:3,lg:1}
                  }}
                >
                  {contt.title}
                </Typography>
                {contt.contents.map((content, ctti) => (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      gap:1,
                    }}
                    index={ctti}
                  >
                    <Box>
                      <img src={data.StarImg} />
                    </Box>
                    <Typography
                      sx={{
                        fontWeight: "400",
                        textAlign: "justify",
                        fontSize: { lg: 20, md: 20, sm: 18, xs: 16 },
                        paddingBottom: { lg: 3, md: 2, sm: 1, xs: 1 },
                      }}
                    >
                      {content.Text1}{" "}
                      <b>{content.hilight} </b>{" "}{content.Text2}
                    </Typography>
                  </Box>
                ))}
              </CustomTabPanel>
            ))}
          </Grid>
        </Grid>
      ))}
    </>
  );
};

export default KlusBring;

import React from 'react'
import Layout from '../component/Layout'
import SubHeader from '../component/SubHeader'
import { Box } from '@mui/material'
import { Container } from "@mui/material";
import { UnlockData } from '../data/SubHeaderData';
import CvForm from '../component/CvForm';

const SubmityourCV = () => {
  return (
    <Layout>
    <Container sx={{maxWidth:{lg:"lg", xl:"xl",md:"md",sm:"sm",xs:"xs"}}}>
      <Box sx={{ mt:15}}>
        <SubHeader data={UnlockData}/>
      </Box>
      <Box py={8}>
        <CvForm/>
      </Box>
     </Container>
    </Layout>
  )
}

export default SubmityourCV
import React from "react";
import Layout from "../component/Layout";
import { Container } from "@mui/material";
import SubHeader from "../component/SubHeader";
import { Box } from '@mui/material'
import { BrowsSubhead } from "../data/SubHeaderData";
import bottamBrowsJob from "../asset/images/bottamBnrImgs/bottamBrowsJob.png";
import JobIndex from "../component/BrowsJobs/JobIndex";
import Jobcard from "../component/BrowsJobs/Jobcard";



const BrowseJobs = () => {
  return (
    <Layout>
     <Container sx={{maxWidth:{lg:"lg", xl:"xl",md:"md",sm:"sm",xs:"xs"}}}>
      <Box sx={{ mt:15}}>
        <SubHeader data={BrowsSubhead} />
      </Box>
{/* 
    <Box>
      <JobIndex/>
    </Box> */}
    <Box>
      <Jobcard/>
    </Box>

      <Box sx={{textAlign:'center'}} py={5}>
        <img src={bottamBrowsJob} width='100%' />
      </Box>
     </Container>
    </Layout>
  );
};

export default BrowseJobs;

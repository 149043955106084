import Polo1 from '../asset/images/Merchandise/Polo/polo1.png'
import Polo2 from '../asset/images/Merchandise/Polo/polo2.png'
import Polo3 from '../asset/images/Merchandise/Polo/polo3.png'

import Crew1 from '../asset/images/Merchandise/Crew/crew1.png'
import Crew2 from '../asset/images/Merchandise/Crew/crew2.png'
import Crew3 from '../asset/images/Merchandise/Crew/crew3.png'

import Hoodies1 from '../asset/images/Merchandise/Hoodie/hoodie1.png'
import Hoodies2 from '../asset/images/Merchandise/Hoodie/hoodie2.png'
import Hoodies3 from '../asset/images/Merchandise/Hoodie/hoodie3.png'

import Jackets1 from '../asset/images/Merchandise/Jackets/jacket1.png'
import Jackets2 from '../asset/images/Merchandise/Jackets/jacket2.png'
import Jackets3 from '../asset/images/Merchandise/Jackets/jacket3.png'

export const ApparelsData = [
  {
    id: 1,
    head: "Polo T-shirts",
    text: "Effortlessly blend style and comfort with our classic polo t-shirts.",
    image: [
      { id: 1, image:Polo1 },
      { id: 2, image:Polo2 },
      { id: 3, image:Polo3 },
    ],
  },
  {
    id: 2,
    head: "Crew Neck T-shirts",
    text: "Keep track of important meetings, deadlines, and ideas with our premium diaries.",
    image: [
      { id: 1, image: Crew1 },
      { id: 2, image: Crew2 },
      { id: 3, image: Crew3 },
    ],
  },
  {
    id: 3,
    head: "Hoodies with Zipper and without Zipper",
    text: "Stay cozy and on-trend with our stylish hoodies, available with and without zippers.",
    image: [
      { id: 1, image: Hoodies1 },
      { id: 2, image: Hoodies2 },
      { id: 3, image: Hoodies3 },
    ],
  },
  {
    id: 4,
    head: "Jackets",
    text: "Brave the elements in style with our durable and fashionable jackets.",
    image: [
      { id: 1, image: Jackets1 },
      { id: 2, image: Jackets2 },
      { id: 3, image: Jackets3 },
    ],
  },
];

import bnrHome from "../asset/images/bannerImges/bnrHome.png";
import BrowsJobBnr from "../asset/images/bannerImges/BrowsJobBnr.png";
import myCvBnr from "../asset/images/bannerImges/myCvBnr.png";
import referBnr from "../asset/images/bannerImges/referBnr.png";
import talentBnr from "../asset/images/bannerImges/talentBnr.png";
import skillBnr from "../asset/images/bannerImges/skillBnr.png";
import aboutBnr from "../asset/images/bannerImges/aboutbnr.png";
import marchBnr from "../asset/images/bannerImges/march.png";
import workforceBnr from "../asset/images/bannerImges/workforceBnr.jpg";
import integratedBnr from "../asset/images/bannerImges/integratedBnr.png";
import efficientBnr from "../asset/images/bannerImges/efficientBnr.png";



export const HomesubHead = {
  firsthead:
    "Transforming IT with Expertise, Connecting Talent with Opportunity, Elevating Skills for Tomorrow",
  bottamText:
    "Klues leads in IT excellence, matches talent to opportunities, and offers comprehensive training to prepare for the future.​",
  headerImg: bnrHome,
};

export const BrowsSubhead = {
  firsthead:
    "Discover a world of endless opportunities and growth potential with Klues",
  bottamText:
    "Explore our diverse array of career opportunities tailored to match your skills and aspirations. Browse through our curated selection of jobs and take the first step towards your dream career today!​",
  headerImg: BrowsJobBnr,
};

export const UnlockData = {
  firsthead: "Submit Your Resume and Ready to Soar New Heights",
  bottamText: "Seamlessly Apply with Confidence and Let Your CV Speak Volumes",
  headerImg: myCvBnr,
};

export const Double = {
  firsthead: "Help Us Catch A Rising Star !  Refer A Friend",
  bottamText: "Share the success, watch the success and boost your Network",
  headerImg: referBnr,
};

export const TrainingSubHead = {
  firsthead:
    "Expand Your Workforce Horizons & Boost Efficiency with our Inclusive Training Solutions",
  bottamText:
    "Personalized training tailored according to the needs, ensuring the greatest positive effect on your skills and abilities. Our customized approach guarantees training that directly addresses your specific goals for maximum impact and effectiveness.",
  headerImg: skillBnr,
};

export const Discover = {
  firsthead:
    "Discover a Suite of Tailored Solution Designed to Elevate Your Business",
  bottamText:
    "Empowering individuals for future success by providing career opportunities and support in reaching their goals. Our focus is on building pathways for seekers to thrive in their chosen careers through guidance and resources.",
  headerImg: talentBnr,
};

export const Unleashing = {
  firsthead: "Unleashing Potential Through Our Recruitment & Skill Training",
  bottamText:
    "Empowering the future and building career opportunity for the seekers.",
  headerImg: aboutBnr,
};

export const merchandise = {
  firsthead: "Welcome to Klues - Your partner in Corporate Merchandise",
  bottamText:
    "Our primary objective at Klues is simple : to offer value-driven solutions that exceed expectations. We pride ourselves on delivering functional, high-quality, and innovative products at competitive rates, complemented by a suite of customer-centric service offerings. With a commitment to excellence and innovation, we specialize in providing top-quality merchandise tailored to meet your corporate needs. From Employee Onboarding Kits to Office Essentials, E-Gadgets, Apparels, and Customized Welcome Kits, we offer a one-stop solution for all your merchandising requirements.",
  headerImg: marchBnr,
};

export const workforceTop = {
  firsthead: "Empowering Your Workforce with Effortless Management, Maximum Efficiency, and Enhanced Productivity Every Day",
  bottamText:
    "We Optimize productivity, scheduling, and resource allocation with effective strategies in comprehensive workforce management solutions.",
  headerImg: workforceBnr,
};

export const  integratedTop= {
  firsthead: "Simplify operations, Amplify Productivity with our All in One Integrated IT solutions",
  bottamText:
    "Unlock efficiency and security with our comprehensive IT services—from integration to distributed networking, tailored for success.",
  headerImg: integratedBnr,
};

export const efficientTop = {
  firsthead: "Empower productivity with Automation Solutions for Error-free, Efficient Operations",
  bottamText:
    "Experience a transformative journey with our customized automation solutions, boosting productivity and efficiency uniquely.",
  headerImg: efficientBnr,
};



import React from "react";
import Layout from "../component/Layout";
import {
  Box,
  Container,
  Divider,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import StarBorderPurple500Icon from "@mui/icons-material/StarBorderPurple500";
import { PrivacyData } from "../data/TermsPrivacyData";

const Privacy = () => {
  return (
    <div>
      <Layout>
        <div>
          <Container
            sx={{
              maxWidth: { lg: "lg", xl: "xl", md: "md", sm: "sm", xs: "xs" },
            }}
          >
            <Box mt={15}>
              <Grid container spacing={2} py={5}>
                <Grid item lg={4} md={8} xs={12}>
                  <Typography
                    color="primary"
                    sx={{
                      fontSize: { lg: 45, md: 30, xs: 26 },
                      fontWeight: "bold",
                    }}
                  >
                    Privacy & Policy
                  </Typography>
                </Grid>
                <Grid item lg={8} md={6} xs={12}>
                  {" "}
                  <Typography
                    sx={{
                      fontSize: { lg: 20, md: 18, xs: 16 },
                      textAlign: "justify",
                    }}
                  >
                    Your privacy is critical to us. Likewise, we have built up
                    this Policy with the end goal you should see how we gather,
                    utilize, impart and reveal and make utilization of
                    individual data. The following blueprints our privacy
                    policy.
                  </Typography>
                </Grid>
              </Grid>

              <Divider sx={{ color: "secondary" }} />

              <Box py={5}>
                {PrivacyData.points.map((point) => (
                  <ListItem sx={{ position: "relative" }}>
                    <ListItemIcon
                      sx={{ position: "absolute", top: 15, left: 0 }}
                    >
                      <StarBorderPurple500Icon
                        sx={{
                          color: "primary.main",
                          fontSize: { lg: 25, md: 22, xs: 20 },
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        style: { fontSize: 20, marginLeft: "1.5em" },
                      }}
                      component="typography"
                      primary={point}
                    />
                  </ListItem>
                ))}
                <Typography
                  py={2}
                  sx={{
                    fontSize: { lg: 20, md: 18, xs: 16 },
                    textAlign: "justify",
                  }}
                >
                  {PrivacyData.bottamText}
                </Typography>
              </Box>
            </Box>
          </Container>
        </div>
      </Layout>
    </div>
  );
};

export default Privacy;

import { Grid, Typography } from "@mui/material";
import React from "react";

const ServiceIntro = ({data}) => {
  return (
    <div>
      <Grid container spacing={2} py={5}>
        <Grid item lg={3.5} md={5}>
          <Typography
            sx={{ fontSize: { lg: 40, md: 32, xs: 20 }, fontWeight: "bold" }}
          >
            {data.heading}
          </Typography>
        </Grid>
        <Grid item lg={8.5} md={7}>
          <Typography
            sx={{ fontSize: { lg: 18, md: 16, xs: 14 }, color: "grey" }}
          >
            {data.description}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default ServiceIntro;

import React from 'react'
import Layout from '../../component/Layout'
import { Box, Container } from '@mui/material'
import SubHeader from '../../component/SubHeader'
import ServiceIntro from '../../component/ServiceIntro'
import ServiceCard2 from '../../component/ServiceCard2'
import { IntegratedIntro } from '../../data/serviceHead'
import { integratedTop } from '../../data/SubHeaderData'
import { intergrateIT } from '../../data/subServiceData'
import  bottamIntegrated from "../../asset/images/bottamBnrImgs/bottamIntegrated.jpg";

const IntegratedSolutions = () => {
  return (
    <Layout>
    <Container
      sx={{ maxWidth: { lg: "lg", xl: "xl", md: "md", sm: "sm", xs: "xs" } }}
    >
      <Box sx={{ mt: 15 }}>
        <SubHeader data={integratedTop} />
      </Box>

      <Box>
        <ServiceIntro data={IntegratedIntro} />
      </Box>
      <Box pb={5}>
        <ServiceCard2 data={intergrateIT} />
      </Box>

      <Box sx={{ textAlign: "center" }} py={5}>
          <img src={bottamIntegrated} width="100%" style={{borderRadius:"35px"}}  />
        </Box>
    </Container>
  </Layout>
  )
}

export default IntegratedSolutions
import React from "react";
import Layout from "../../component/Layout";
import SubHeader from "../../component/SubHeader";
import { Box } from "@mui/material";
import { Container } from "@mui/material";
import { TrainingSubHead } from "../../data/SubHeaderData";
import bottamSkill from "../../asset/images/bottamBnrImgs/bottamSkill.png";
import WhyKlueCard from "../../component/WhyKlueCard";
import ExcelCards from "../../component/ExcelCards";
import { skillBuilding } from "../../data/TalentServiceData";

const SkillBuilding = () => {
  return (
    <Layout>
      <Container
        sx={{ maxWidth: { lg: "lg", xl: "xl", md: "md", sm: "sm", xs: "xs" } }}
      >
        <Box sx={{ mt:15}}>
          <SubHeader data={TrainingSubHead} />
        </Box>

        <Box py={5}>
          <ExcelCards data={skillBuilding}/>
        </Box>

        

        {/* <Box py={5}>
          <WhyKlueCard />
        </Box> */}

        <Box sx={{ textAlign: "center" }} py={5}>
          <img src={bottamSkill} width="100%" />
        </Box>
      </Container>
    </Layout>
  );
};

export default SkillBuilding;

import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import logo from "../asset/images/logo.png";
import StarBorderPurple500OutlinedIcon from "@mui/icons-material/StarBorderPurple500Outlined";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import LetsContBtn from "./LetsContBtn";
import MobileNavbar from "./MobileNavbar";

const navTitle = [
  {
    icon: <StarBorderPurple500OutlinedIcon color="primary" />,
    title: "Our Portfolio",
    menu: [
   
      {
        title: "Efficient Automation",
        link: "/efficient-automation",
      },
      {
        title: "Integrated IT Solutions",
        link: "/integrated-solutions",
      },
      {
        title: "Recruitment Services",
        link: "/recruitment-services",
      },
      {
        title: "Training Services",
        link: "/training-services",
      },
      {
        title: "Workforce Management",
        link: "/workforce-management",
      },
    ],
  },
  {
    icon: <StarBorderPurple500OutlinedIcon color="primary" />,
    title: "Industries",
    link: "/industries",
  },
  {
    icon: <StarBorderPurple500OutlinedIcon color="primary" />,
    title: "Career",
    menu: [
      {
        title: "Browse Jobs",
        link: "/browse-job",
      },
      {
        title: "Submit Your CV",
        link: "/submit-cv",
      },
      {
        title: "Refer Your Friend",
        link: "/refer-friend",
      },
    ],
  },
];

const Header = () => {
  return (
    <>
      <AppBar
        position="fixed"
        sx={{ backgroundColor: "white", color: "black", boxShadow: "none" }}
      >
        <Container
          sx={{
            maxWidth: { lg: "lg", xl: "xl", md: "md", sm: "sm", xs: "xs" },
          }}
        >
          <Toolbar disableGutters>
            <Link to="/">
              <Box
                component="img"
                pb={1}
                src={logo}
                alt="logo"
                sx={{ display: "flex", ml: { md: 2, sm: 0 } }}
                width="145px"
              />
            </Link>

            {/*---------------- Mobile View------------------ */}
            <Box
              sx={{ flexGrow: 1, display: { xs: "flex", md: "none" }, ml: 5 }}
            >
              <MobileNavbar navTitle={navTitle} />
            </Box>
            {/*---------------- Mobile View------------------ */}

            <Box flexGrow={8} />
            <Box
              sx={{
                flexGrow: { lg: 0.5, md: 5 },
                display: { xs: "none", md: "flex" },
              }}
            >
              <Navbar navTitle={navTitle} />
            </Box>

            <Box
              sx={{
                flexGrow: 0,
                display: { xs: "none", sm: "block", md: "block", lg: "block" },
              }}
            >
              <LetsContBtn />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

export default Header;

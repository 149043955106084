import { Button } from '@mui/material'
import React from 'react'
import { HiArrowLongRight } from "react-icons/hi2";




const LetsContBtn = () => {
  return (
    <div>
  <Button 
          sx={{
          
            fontWeight: "bold",
            borderRadius: "5px",
          }}
          size="medium"
          color="primary"
          variant="contained"
          component="a"
          href='mailTo:sales@klues.co.in'
          endIcon={
            <HiArrowLongRight/>
          }
        >
            Lets Connect
        </Button>

    </div>
  )
}

export default LetsContBtn
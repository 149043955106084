import { Box, Modal, Typography,Button, ThemeProvider } from "@mui/material";
import BaseTheme from "./theme";
import { HiArrowLongRight } from "react-icons/hi2";


const ModalConfirm = ({text1,open,close}) => {




  

  return (
    <div>
        <ThemeProvider
         theme={BaseTheme}>
      <Modal open={open} onClose={close}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            boxShadow: 10,
            border:"none",
            borderRadius:5,
            p: 5,
          }}
        >
          <Typography variant="h6"  component="h2">
          {text1} Form Submitted Successfully!
          </Typography>
          <Typography  variant="body1">
            Thank you for your submission.
          </Typography>
          <Button sx={{color:"white", mt:2, px:2}} endIcon={<HiArrowLongRight/>} variant="contained" onClick={close}>Close</Button>
        </Box>
      </Modal>
      </ThemeProvider>
    </div>
  );
};

export default ModalConfirm;
